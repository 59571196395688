import React, { useState } from "react"
import withFirebaseAuth from "react-with-firebase-auth"
import { providers, getAuth } from "../../../state/firebase"
import { Helmet } from "react-helmet"
import { Button, Input, Divider, Spin } from "antd"
import { BrowserRouter as Router } from "react-router-dom"

import { StateProvider } from "../../../state"
import { reducer } from "../../../state/reducer"
import { defaultState } from "../../../state/defaultState"
import css from "./index.module.css"
import AppLayout from "../AppLayout"
import Loading from "../../common/Loading"

import "./index.css"

const SignedInApp = (props) => {
  const { signOut } = props
  return (
    <StateProvider initialState={defaultState} reducer={reducer}>
      <Router>
        <AppLayout signout={signOut} {...props} />
      </Router>
    </StateProvider>
  )
}

const SignIn = (props) => {
  const { signInWithGoogle, signInWithEmailAndPassword } = props
  const [emailPassword, setEmailPassword] = useState({
    email: "",
    password: "",
  })

  const emailPasswordSignIn = () => {
    signInWithEmailAndPassword(emailPassword.email, emailPassword.password)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
  }

  return (
    <div className={css.signInContainer}>
      <div className={css.signIn}>
        <Button type="primary" ghost onClick={signInWithGoogle}>
          Sign in with Google
        </Button>
        <Divider>OR</Divider>
        <Input
          className={css.margin}
          type="email"
          placeholder="Email"
          id="formEmail"
          onChange={(e) =>
            setEmailPassword({ ...emailPassword, email: e.target.value })
          }
        />
        <Input
          className={css.margin}
          type="password"
          placeholder="Password"
          id="formPassword"
          onChange={(e) =>
            setEmailPassword({ ...emailPassword, password: e.target.value })
          }
          onPressEnter={emailPasswordSignIn}
        />
        <Button
          className={css.emailAndPassword}
          type="primary"
          ghost
          onClick={emailPasswordSignIn}
          disabled={!emailPassword.email || !emailPassword.password}
        >
          Sign in with Email & Password
        </Button>
      </div>
    </div>
  )
}

function App(props) {
  const { user } = props
  return (
    <div className="App">
      <Helmet>
        <style>{"body { background-color: #25292C; }"}</style>
      </Helmet>
      {user ? (
        <SignedInApp {...props} />
      ) : // No user signed in is null, unknown signed-in state is undefined.
      user === undefined ? (
        <Loading empty />
      ) : (
        <SignIn {...props} />
      )}
    </div>
  )
}

async function appWithAuth() {
  return withFirebaseAuth({
    providers,
    firebaseAppAuth: await getAuth(),
  })(App)
}

export default appWithAuth()
