import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Tabs, List } from "antd"
import css from "./index.module.css"
import cx from "classnames"
import { useGlobalState } from "../../../state"

export default function LensTree({ treeData, treeType, ...props }) {
  const { TabPane } = Tabs
  const [state, dispatch] = useGlobalState()
  const [make, setMake] = useState(null)
  const [model, setModel] = useState(null)
  const [imageDiameter, setImageDiameter] = useState(null)
  const [focalLength, setFocalLength] = useState(null)
  const [serialNumber, setSerialNumber] = useState(null)
  const [activeKey, setActiveKey] = useState("make")
  let history = useHistory()

  const handleTreeSelect = (e, item) => {
    switch (item.field) {
      case "make":
        setMake(item)
        setActiveKey("model")
        break
      case "model":
        setModel(item)
        setActiveKey("focalLength")
        break
      case "focalLength":
        setFocalLength(item)
        if (item.children) setActiveKey("imageDiameter")
        if (item.lenses) setActiveKey("serialNumber")
        break
      case "imageDiameter":
        setImageDiameter(item)
        break
      default:
        break
    }
    if (item.isLeaf) {
      const {
        make,
        model,
        focal_length_mm,
        supported,
        type_id,
        calibrated_front_rear,
        image_diameter,
        tstop,
        length,
      } = item
      const lens = {
        make,
        model,
        focal_length_mm,
        supported,
        type_id,
        calibrated_front_rear,
        image_diameter,
        tstop,
        length,
      }
      dispatch({
        type: "SET_CURRENT_SCAN_LENS",
        lens: lens,
      })
    } else {
      dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: null })
    }
  }

  // Add lens ID to path if using lens tree on inventory page.
  const handleSerialNumberSelect = (e, item) => {
    setSerialNumber(item)
    history.push({
      pathname: `/inventory/${item.lens_id}`,
    })
  }

  const handleTitleClick = (title) => {
    switch (title) {
      case "make":
        setMake(null)
        setModel(null)
        setFocalLength(null)
        setSerialNumber(null)
        break
      case "model":
        setModel(null)
        setFocalLength(null)
        setSerialNumber(null)
        break
      case "focalLength":
        setFocalLength(null)
        setSerialNumber(null)
        break
      case "serialNumber":
        setSerialNumber(null)
        break
      default:
        break
    }
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: null })
    setActiveKey(title)
  }

  const tree = (data = treeData) => {
    return (
      <List
        dataSource={data}
        bordered={false}
        renderItem={(item) => (
          <List.Item
            onClick={(e) => handleTreeSelect(e, item)}
            className={
              item === focalLength || item === imageDiameter
                ? cx(css.lensTreeItem, css.selected)
                : css.lensTreeItem
            }
          >
            <span>
              {item.icon} {item.title}
            </span>
          </List.Item>
        )}
      ></List>
    )
  }

  return (
    <div className="App-header">
      <Tabs
        activeKey={activeKey}
        onTabClick={handleTitleClick}
        className={css.lensTreeTab}
      >
        <TabPane tab="Make" key="make" className={css.lensTreeTab}>
          <div
            className={
              treeType === "inventory" ? css.paneBody : css.paneBodyScanWizard
            }
          >
            {tree()}
          </div>
        </TabPane>
        <TabPane
          tab="Model"
          disabled={make === null}
          key="model"
          className={css.lensTreeTab}
        >
          <div
            className={
              treeType === "inventory" ? css.paneBody : css.paneBodyScanWizard
            }
          >
            {make && tree(make.children)}{" "}
          </div>
        </TabPane>
        <TabPane
          tab="Focal Length"
          disabled={model === null}
          key="focalLength"
          className={css.lensTreeTab}
        >
          <div
            className={
              treeType === "inventory" ? css.paneBody : css.paneBodyScanWizard
            }
          >
            {make && model && tree(model.children)}{" "}
          </div>
        </TabPane>
        {make && model && focalLength && !focalLength.isLeaf && (
          <TabPane
            tab="Image Circle"
            key="imageDiameter"
            className={css.lensTreeTab}
          >
            <div
              className={
                treeType === "inventory" ? css.paneBody : css.paneBodyScanWizard
              }
            >
              {tree(focalLength.children)}{" "}
            </div>
          </TabPane>
        )}
        {treeType === "inventory" && (
          <TabPane
            tab="Serial Number"
            disabled={focalLength === null}
            key="serialNumber"
            className={css.lensTreeTab}
          >
            {make && model && focalLength && focalLength.lenses && (
              <List
                dataSource={focalLength.lenses}
                bordered={false}
                renderItem={(item) => (
                  <List.Item
                    onClick={(e) => handleSerialNumberSelect(e, item)}
                    className={css.lensTreeItem}
                    field="serialNumber"
                  >
                    <span>{item.serial_number}</span>
                  </List.Item>
                )}
              ></List>
            )}
          </TabPane>
        )}
      </Tabs>
    </div>
  )
}
