import React, { useState } from "react"
import { Input, Row, Col, Select, Button } from "antd"
import { ArrowLeftOutlined } from "@ant-design/icons"
import cx from "classnames"
import Header from "../Header"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import { firestore as db } from "../../../state/firebase"
import { STEPS } from "../../../state/constants"

const { Option } = Select
const InputGroup = Input.Group

const defaultMount = "PL"
const fullFrameImgDiam = 44
const super35ImgDiam = 32
const super16ImgDiam = 15

export default function AddLens({ setStep }) {
  const [state, dispatch] = useGlobalState()
  const [duplicateFound, setDuplicateFound] = useState(false)
  const [foundLens, setFoundLens] = useState({})
  const [serialNumber, setSerialNumber] = useState(undefined)

  const [lens, setLens] = useState({
    make: state.currentScanLens ? state.currentScanLens.make : "",
    model: state.currentScanLens ? state.currentScanLens.model : "",
    focal_length_mm:
      state.currentScanLens && state.currentScanLens.focal_length_mm
        ? state.currentScanLens.focal_length_mm
        : 0,
    length:
      state.currentScanLens && state.currentScanLens.length
        ? state.currentScanLens.length
        : 0,
    tstop:
      state.currentScanLens && state.currentScanLens.tstop
        ? state.currentScanLens.tstop
        : 0,
    mount: defaultMount,
    image_diameter:
      state.currentScanLens && state.currentScanLens.image_diameter
        ? state.currentScanLens.image_diameter
        : 0,
    calibrated_front_rear: false,
    newLens: state.currentScanLens === null,
  })

  const handleChange = (e) => {
    const internalLens = { ...lens }
    switch (e.target.id) {
      case "make":
        internalLens["make"] = e.target.value.trim()
        break
      case "model":
        internalLens["model"] = e.target.value.trim()
        break
      case "focal_length_mm":
        internalLens["focal_length_mm"] = parseInt(e.target.value)
        break
      case "physical_length":
        internalLens["length"] = parseInt(e.target.value)
        break
      case "tStop":
        internalLens["tstop"] = parseFloat(e.target.value)
        break
      default:
        throw new Error("No setter for this id")
    }
    lensAlreadyInDB(internalLens)
    setLens(internalLens)
  }

  const compare = (val1, val2) => {
    if (!val1 || !val2) return false
    if (typeof val1 === "string") {
      return val1.toLowerCase().trim() === val2.toLowerCase().trim()
    } else {
      return val1 === val2
    }
  }

  const lensAlreadyInDB = (lens) => {
    for (let existing of state.lensTypes) {
      if (
        compare(lens.make, existing.make) &&
        compare(lens.model, existing.model) &&
        compare(lens.focal_length_mm, existing.focal_length_mm)
      ) {
        setDuplicateFound(true)
        setFoundLens(existing)
        break
      } else {
        setDuplicateFound(false)
      }
    }
  }

  const handleMountChange = (mount) => setLens({ ...lens, mount })
  const handleImageDiameterChange = (imageDiameter) =>
    setLens({ ...lens, image_diameter: imageDiameter })

  const handleSerialNumberChange = (e) => setSerialNumber(e.target.value)

  const handleClick = async () => {
    if (
      lens.make === "" ||
      lens.model === "" ||
      lens.focal_length_mm === 0 ||
      lens.length === 0 ||
      lens.tstop === 0 ||
      serialNumber === undefined
    ) {
      alert("All fields required!")
      return
    }
    let lens_type = undefined
    if (state.currentScanLens !== undefined) {
      lens_type = await db
        .updateLens(state.currentScanLens.type_id, lens)
        .then((ref) => ref.get())
    } else {
      lens_type = await db.addLens(lens).then((ref) => ref.get())
    }
    db.addSerialNumber(serialNumber, lens_type.id, state.userOrgIds[0]).then(
      (data) => {
        dispatch({
          type: "SET_CURRENT_SCAN_LENS",
          lens: {
            ...lens_type.data(),
            type_id: lens_type.id,
            serial_number: data.serial_number,
            id: data.id,
          },
        })
      }
    )

    setStep(STEPS.CALIBRATE_LENS)
  }

  const handleUseExisting = () => {
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: foundLens })
    setStep(STEPS.SELECT_SERIAL_NUMBER)
  }

  return (
    <div>
      <Header>
        {state.currentScanLens !== null ? "Confirm Lens Details" : "Add A Lens"}
      </Header>
      <InputGroup>
        <Row gutter={8}>
          <Col span={6}>
            <div className={css.inputTitle}>Make</div>
          </Col>
          <Col span={18}>
            {state.currentScanLens !== null &&
            state.currentScanLens.make !== null ? (
              <Input id="make" value={state.currentScanLens.make} disabled />
            ) : (
              <Input id="make" onChange={handleChange} />
            )}
          </Col>
        </Row>
      </InputGroup>
      <br />
      <InputGroup>
        <Row gutter={8}>
          <Col span={6}>
            <div className={css.inputTitle}>Model</div>
          </Col>
          <Col span={18}>
            {state.currentScanLens !== null &&
            state.currentScanLens.model !== null ? (
              <Input id="model" value={state.currentScanLens.model} disabled />
            ) : (
              <Input id="model" onChange={handleChange} />
            )}
          </Col>
        </Row>
      </InputGroup>
      <br />
      <InputGroup>
        <Row gutter={8}>
          <Col span={6}>
            <div className={css.inputTitle}>Focal Length</div>
          </Col>
          <Col span={18}>
            {state.currentScanLens !== null &&
            state.currentScanLens.focal_length_mm !== null ? (
              <Input
                id="focal_length_mm"
                value={state.currentScanLens.focal_length_mm}
                addonAfter="mm"
                disabled
              />
            ) : (
              <Input
                id="focal_length_mm"
                addonAfter="mm"
                onChange={handleChange}
              />
            )}
          </Col>
        </Row>
      </InputGroup>
      <br />
      <InputGroup>
        <Row gutter={8}>
          <Col span={6}>
            <div className={css.inputTitle}>Physical Length</div>
          </Col>
          <Col span={18}>
            {state.currentScanLens !== null &&
            state.currentScanLens.length !== null ? (
              <Input
                id="physical_length"
                value={state.currentScanLens.length}
                addonAfter="mm"
                disabled
              />
            ) : (
              <Input
                id="physical_length"
                addonAfter="mm"
                onChange={handleChange}
              />
            )}
          </Col>
        </Row>
      </InputGroup>
      <br />
      <InputGroup>
        <Row gutter={8}>
          <Col span={6}>
            <div className={css.inputTitle}>T-stop</div>
          </Col>
          <Col span={18}>
            {state.currentScanLens !== null &&
            state.currentScanLens.tstop !== null ? (
              <Input id="tStop" value={state.currentScanLens.tstop} disabled />
            ) : (
              <Input id="tStop" onChange={handleChange} />
            )}
          </Col>
        </Row>
      </InputGroup>
      {state.currentScanLens === null && (
        <div>
          <br />
          <InputGroup>
            <Row gutter={8}>
              <Col span={6}>
                <div className={css.inputTitle}>Mount</div>
              </Col>
              <Col span={18}>
                <Select
                  id="mount"
                  defaultValue={defaultMount}
                  style={{ width: 120 }}
                  onChange={handleMountChange}
                >
                  <Option value="PL">PL</Option>
                  <Option value="LPL">LPL</Option>
                </Select>
              </Col>
            </Row>
          </InputGroup>
        </div>
      )}
      <br />
      <InputGroup>
        <Row gutter={8}>
          <Col span={6}>
            <div className={css.inputTitle}>Image Diameter</div>
          </Col>
          <Col span={18}>
            <Select
              id="imageDiameter"
              defaultValue={super35ImgDiam}
              style={{ width: 120 }}
              onChange={handleImageDiameterChange}
            >
              <Option value={fullFrameImgDiam}>Full Frame</Option>
              <Option value={super35ImgDiam}>Super 35</Option>
              <Option value={super16ImgDiam}>Super 16</Option>
            </Select>
          </Col>
        </Row>
      </InputGroup>
      <br />
      <InputGroup>
        <Row gutter={8}>
          <Col span={6}>
            <div className={css.inputTitle}>Serial Number</div>
          </Col>
          <Col span={18}>
            <Input id="serialNumber" onChange={handleSerialNumberChange} />
          </Col>
        </Row>
      </InputGroup>
      <br />
      {duplicateFound ? (
        <div className={css.flexButton}>
          <div className={css.button}>
            <div className={css.smallText}>This lens is similar to the</div>
            <div className={cx(css.boldText, css.smallText)}>
              {`${foundLens.make} ${foundLens.model} ${foundLens.focal_length_mm}mm`}
            </div>
            <div className={css.smallText}>already in your database</div>
          </div>
          <div className={css.button}>
            <Button type="primary" ghost onClick={handleUseExisting}>
              <ArrowLeftOutlined style={{ color: "#A288FF" }} />
              Use
            </Button>
          </div>
          <div>
            <Button type="danger" ghost onClick={handleClick}>
              Create Anyway
            </Button>
          </div>
        </div>
      ) : (
        <div className={css.buttonGroup}>
          <Button type="primary" ghost onClick={handleClick} block>
            {state.currentScanLens ? "Continue" : "Create"}
          </Button>
        </div>
      )}
    </div>
  )
}
