import React, { useState, useEffect } from "react"
import { useGlobalState } from "../../state"
import { firestore as db } from "../../state/firebase"
import {
  Affix,
  Anchor,
  Divider,
  PageHeader,
  DatePicker,
  Row,
  Col,
  Typography,
} from "antd"
import css from "./index.module.css"
import cx from "classnames"
import ScanHistoryFilter from "./ScanHistoryFilter"
import ScanCard from "../common/ScanCard"
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ClockCircleFilled,
  PieChartFilled,
} from "@ant-design/icons"
import { SCAN_STATUS } from "../../state/constants"
import { generateDateString } from "../../helpers/date"
import Loading from "../common/Loading"

export default function ScanHistory({ ...props }) {
  const [state, dispatch] = useGlobalState()
  const [scannerFilter, setScannerFilter] = useState(null)
  const [statusFilter, setStatusFilter] = useState(null)
  const [userFilter, setUserFilter] = useState(null)
  const [dateFilter, setDateFilter] = useState([])
  const [orgUsers, setOrgUsers] = useState([])
  const [scanners, setScanners] = useState([])
  const [scans, setScans] = useState([])
  const [scansFetched, setScansFetched] = useState(false)
  const [filteredScans, setFilteredScans] = useState([])
  const [scansByDate, setScansByDate] = useState({})

  const { RangePicker } = DatePicker
  const { Link } = Anchor

  const groupByDate = (scans) => {
    let dates = {}
    scans.forEach((scan) => {
      const day = generateDateString(scan.timestamp.seconds)
      if (dates[day]) {
        dates[day].push(scan)
      } else {
        dates[day] = [scan]
      }
    })
    return dates
  }

  useEffect(() => {
    db.onOrgsForUser(props.user.uid, (organizations) => {
      dispatch({ type: "SET_USER_ORGS_IDS", userOrgIds: organizations })
      if (organizations.length > 1) {
        console.error(
          "Only one organization supported right now; using only the first"
        )
      }
      if (organizations.length === 0 || scansFetched) return

      db.getScans(organizations[0]).then((docs) => {
        docs = docs.filter((x) => x.lens !== undefined)
        setScans(docs)
        setFilteredScans(docs)
        const groupedByDate = groupByDate(docs)
        setScansByDate(groupedByDate)
        const usersSet = new Set() // Generate lists of unique users, scanners.
        const scannersSet = new Set()
        const usersResult = []
        const scannersResult = []
        for (const scan of docs) {
          // Build up Filter options.
          if (!usersSet.has(scan.user.doc_id)) {
            usersSet.add(scan.user.doc_id)
            usersResult.push({
              name: scan.user.displayName
                ? scan.user.displayName
                : scan.user.email,
              doc_id: scan.user.doc_id,
            })
          }
          if (!scannersSet.has(scan.scanner.doc_id)) {
            scannersSet.add(scan.scanner.doc_id)
            scannersResult.push({
              name: scan.scanner.human_name,
              doc_id: scan.scanner.doc_id,
            })
          }
        }
        setOrgUsers(usersResult)
        setScanners(scannersResult)
        setScansFetched(true)
      })
    }).catch((err) => console.log(err))
  }, [])

  // Filter handlers:
  const handleStatusFilterChange = (value) => setStatusFilter(value)

  const handleScannerFilterChange = (value) => setScannerFilter(value)

  const handleUserFilterChange = (value) => setUserFilter(value)

  const handleDateFilterChange = (date) => {
    const start = Date.parse(date[0]) / 1000
    const end = Date.parse(date[1]) / 1000
    setDateFilter([start, end])
  }

  useEffect(() => {
    if (state.userOrgIds.length < 1) return
    const getFilteredScans = async () => {
      return await db
        .getScans(
          state.userOrgIds[0],
          dateFilter,
          userFilter,
          statusFilter,
          scannerFilter
        )
        .then((docs) => {
          docs = docs.filter((x) => x.lens !== undefined)
          setFilteredScans(docs)
          const groupedByDate = groupByDate(docs)
          setScansByDate(groupedByDate)
          setScansFetched(true)
        })
    }
    setScansFetched(false)
    getFilteredScans()
  }, [statusFilter, scannerFilter, userFilter, dateFilter])

  const mapStatusToVisualInfo = (status) => {
    switch (status) {
      case SCAN_STATUS.PROCESSED:
        return {
          icon: <CheckCircleFilled className={cx(css.green, css.icon)} />,
          friendlyStatus: "Report Ready",
          explanation:
            "Tracis has finished scanning this lens and its report is available for download.",
        }
      case SCAN_STATUS.BAD:
        return {
          icon: <CloseCircleFilled className={cx(css.red, css.icon)} />,
          friendlyStatus: "Error",
          explanation:
            "There was an error and this scan was not able to be processed.",
        }
      case SCAN_STATUS.CALIBRATED:
        return {
          icon: <CheckCircleFilled className={cx(css.blue, css.icon)} />,
          friendlyStatus: "Calibrated",
          explanation: "Lens calibrated. Scan again for a full report.",
        }
      case SCAN_STATUS.PARTIALLY_PROCESSED:
        return {
          icon: <CheckCircleFilled className={cx(css.yellow, css.icon)} />,
          friendlyStatus: "Partial Report",
          explanation: "Report available for some surfaces, not all.",
        }
      default:
        return {
          icon: <ClockCircleFilled className={cx(css.purple, css.icon)} />,
          friendlyStatus: "Processing",
          explanation:
            "Tracis is in the process of analyzing this scan. Stay tuned!",
        }
    }
  }

  // Filter Select HTML elements:
  const statusSelect = (
    <ScanHistoryFilter
      key="statusFilter"
      options={[
        { value: SCAN_STATUS.PROCESSED, title: "Report Ready" },
        { value: SCAN_STATUS.PARTIALLY_PROCESSED, title: "Partial Report" },
        { value: SCAN_STATUS.CALIBRATED, title: "Calibrated" },
        { value: SCAN_STATUS.COMPLETED, title: "Processing" },
        { value: SCAN_STATUS.BAD, title: "Error" },
      ]}
      onFilterChange={handleStatusFilterChange}
      placeholder="Status"
    ></ScanHistoryFilter>
  )

  const scannerSelect = scanners.length > 1 && (
    <ScanHistoryFilter
      key="scannerFilter"
      options={scanners.map((scanner) => ({
        value: scanner.doc_id,
        title: scanner.name,
      }))}
      onFilterChange={handleScannerFilterChange}
      placeholder="Scanner"
    ></ScanHistoryFilter>
  )

  const userSelect = orgUsers.length > 1 && (
    <ScanHistoryFilter
      key="userFilter"
      options={orgUsers.map((user) => ({
        value: user.doc_id,
        title: user.name,
      }))}
      onFilterChange={handleUserFilterChange}
      placeholder="User"
    ></ScanHistoryFilter>
  )

  const dateSelect = (
    <RangePicker
      className={css.scanDateRange}
      onChange={handleDateFilterChange}
      separator="to"
      allowClear={true}
      key="dateFilter"
    />
  )

  const filters = [statusSelect, scannerSelect, userSelect, dateSelect]

  return (
    <div className="fadeIn">
      <div>
        <Affix>
          <PageHeader
            title=""
            className={css.filters}
            subTitle={state.user}
            extra={filters}
          />
        </Affix>
        {scansFetched && filteredScans.length ? (
          Object.entries(scansByDate).map((date) => (
            <div key={date[0]} className="fadeIn">
              <Anchor
                offsetTop={64}
                targetOffset={128}
                className={css.dateAnchor}
              >
                <div className={css.innerDate}></div>
                <Divider className={css.dateDivider}>
                  <div
                    className={css.dividerText}
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    {date[0]}
                  </div>
                </Divider>
              </Anchor>
              <Row className={css.layer1}>
                {date[1].map((scan) => (
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    key={scan.timestamp}
                  >
                    <ScanCard
                      key={scan.timestamp}
                      scan={scan}
                      mapStatusToVisualInfo={mapStatusToVisualInfo}
                      {...props}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ))
        ) : scansFetched ? (
          "No Scans found"
        ) : (
          <Loading title="Scans" />
        )}
      </div>
    </div>
  )
}
