import React, { useState, useEffect } from "react"
import { Col, Row, Typography, Icon, Empty } from "antd"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import css from "./ScansList.module.css"
import cx from "classnames"
import getInlineStyles from "./getInlineStyles"
import useWindowDimensions from "../../helpers/useWindowDimensions"
import { toMonthDay } from "../../helpers/date"
import gears from "../../images/gears.png"
import Loading from "../common/Loading"

const ScansList = ({
  scans,
  leftScan,
  rightScan,
  surface,
  compareScans,
  noScans,
  ...props
}) => {
  const [scale, setScale] = useState(1) // React-zoom-pan-pinch scale property.
  const [positionX, setPositionX] = useState(0) // React-zoom-pan-pinch positionX property.
  const [positionY, setPositionY] = useState(0) // React-zoom-pan-pinch positionY property.
  const [minScale, setMinScale] = useState(0.75) // React-zoom-pan-pinch minScale property.
  const [imageStyle, setImageStyle] = useState({}) // Inline style for surface image(s).
  const [windowStyle, setWindowStyle] = useState({}) // Inline style for image window(s).
  const [titleStyle, setTitleStyle] = useState({}) // Inline style for image date.
  const [rowClass, setRowClass] = useState("") // Inline style for antd Row(s).
  const [imagesStacked, setImagesStacked] = useState(false) // View images side-by-side or stacked.
  const { height, width } = useWindowDimensions() // Hook for getting window dimensions.
  const { Title } = Typography

  // Update react-zoom-pan-pinch properties upon user zoom/pan.
  const update = (coordinates) => {
    setScale(coordinates.scale)
    setPositionX(coordinates.positionX)
    setPositionY(coordinates.positionY)
  }

  const noImageText = (surface) => {
    const surfaceLowerCase = surface.toLowerCase()
    const surfaceParts = surface.split(" ")
    if (surfaceLowerCase in ["front", "rear"]) {
      return `We didn't see anything on the ${surfaceLowerCase} surface.`
    } else if (surfaceParts.length > 1) {
      return `We didn't see anything on surface ${surfaceParts[1]}.`
    } else {
      return `We didn't see anything on this surface.`
    }
  }

  const imageStillProcessingText = (surface) => {
    const surfaceLowerCase = surface.toLowerCase()
    const surfaceParts = surface.split(" ")
    if (surfaceLowerCase in ["front", "rear"]) {
      return `Apologies, some more time is needed to analyze the ${surfaceLowerCase} surface.`
    } else if (surfaceParts.length > 1) {
      return `Apologies, some more time is needed to analyze surface ${surfaceLowerCase}.`
    } else {
      return `Apologies, some more time is needed to analyze this surface.`
    }
  }

  // Update inline styles upon compare switch or window dimension change (including device rotate).
  useEffect(() => {
    const fullWidth = width - 79
    const fullHeight = height
    const scaleMin =
      fullWidth > fullHeight ? fullHeight / fullWidth : fullWidth / fullHeight
    if (scale === 1) {
      // Set initial image scale and position to touch edges and be centered.
      setMinScale(scaleMin)
      setScale(scaleMin)
      if (fullHeight > width) {
        setPositionX(-1)
        setPositionY((fullHeight - fullWidth) / 2)
      } else {
        setPositionX((fullWidth - fullHeight) / 2)
        setPositionY(-1)
      }
    }
    // In order to keep center of viewport in center of window when switching between
    // single and compare modes, set positionX or positionY to jump back and forth by 1/4
    // viewport width or height (depending on orientation).
    if (compareScans) {
      if (minScale > 0.5) {
        setMinScale(minScale / 2) // Allow zooming all the way out in half screen.
        if (height > width) {
          // Compare scans stacked in portrait mode.

          setPositionY(positionY - fullHeight / 4)
        } else {
          // Compare scans side-by-side in landscape mode.

          setPositionX(positionX - fullWidth / 4)
        }
      }
    } else {
      if (minScale < 0.5) {
        setMinScale(minScale * 2) // Reset minimum scale back to fullscreen mode.
        if (height > width) {
          // View single scan in portrait mode.

          setPositionY(positionY + fullHeight / 4)
        } else {
          // View single scan in landscape mode.

          setPositionX(positionX + fullWidth / 4)
        }
      }
    }
    const style = getInlineStyles(width, height, compareScans) // Calculate image and window inline styling.
    setImageStyle(style.image)
    setWindowStyle(style.window)
    setTitleStyle(style.title)
    const stackImages = width < height
    setImagesStacked(stackImages)
    setRowClass(stackImages ? css.block : css.inline) // View images inline if stacked, block if side-by-side.
  }, [height, width, compareScans])

  return (
    <div>
      {noScans ? (
        <div className={css.noScans}>
          No scans available for selected lens.
          <br />
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
        </div>
      ) : leftScan === undefined ? ( // Loading images.
        <Loading title="Surface" />
      ) : (
        // Left scan.
        <div className={cx(css.scansListContainer, "fadeIn")}>
          <Row className={rowClass}>
            <Col
              xs={compareScans && !imagesStacked ? 12 : 24}
              sm={compareScans && !imagesStacked ? 12 : 24}
              md={compareScans && !imagesStacked ? 12 : 24}
              lg={compareScans && !imagesStacked ? 12 : 24}
              xl={compareScans && !imagesStacked ? 12 : 24}
            >
              <Title type="secondary" style={titleStyle}>
                {toMonthDay(leftScan.timestamp.seconds)}
              </Title>
              <div style={windowStyle}>
                {leftScan.surfaces[surface] === undefined ||
                leftScan.surfaces[surface].still_processing ? (
                  <div className={css.noImageContainer}>
                    {leftScan.surfaces[surface] === undefined ? (
                      <div className={css.centered}>
                        {surface && noImageText(surface)}
                      </div>
                    ) : (
                      <div className={css.stillProcessing}>
                        <div className={css.stillProcessingText}>
                          {surface && imageStillProcessingText(surface)}
                          <br />
                          <br />
                          Please check back later.
                        </div>
                        <img src={gears} className={css.stillProcessingImage} />
                      </div>
                    )}
                  </div>
                ) : (
                  <TransformWrapper
                    positionX={positionX}
                    positionY={positionY}
                    scale={scale}
                    defaultPositionX={positionX}
                    defaultPositionY={positionY}
                    defaultScale={minScale}
                    onZoomChange={update}
                    onPanning={update}
                    onPanningStop={update}
                    wheel={{ step: 13 }}
                    doubleClick={{ disabled: true }}
                    reset={{ disabled: true }}
                    pan={{ paddingSize: 0, velocityBaseTime: 0 }}
                    scalePadding={{ disabled: true }}
                    className={css.wrapper}
                    options={{ minScale: minScale, limitToBounds: false }}
                    style={{ width: `${width / 2}px` }}
                  >
                    <TransformComponent>
                      <img
                        style={imageStyle}
                        key={surface}
                        className={css.fade}
                        src={
                          leftScan &&
                          surface &&
                          leftScan.surfaces[surface].image_url
                        }
                      />
                    </TransformComponent>
                  </TransformWrapper>
                )}
              </div>
            </Col>
            <br />
            {compareScans && (
              // Right scan.
              <Col
                xs={imagesStacked ? 24 : 12}
                sm={imagesStacked ? 24 : 12}
                md={imagesStacked ? 24 : 12}
                lg={imagesStacked ? 24 : 12}
                xl={imagesStacked ? 24 : 12}
                className={imagesStacked ? css.borderTop : css.borderLeft} // Divider between images in compare mode.
              >
                <Title type="secondary" style={titleStyle}>
                  {toMonthDay(rightScan.timestamp.seconds)}
                </Title>
                <div style={{ backgroundColor: "#25292C", ...windowStyle }}>
                  {rightScan.surfaces[surface] === undefined ||
                  rightScan.surfaces[surface].still_processing ? (
                    <div className={css.noImageContainer}>
                      {rightScan.surfaces[surface] === undefined ? (
                        <div className={css.centered}>
                          {surface && noImageText(surface)}
                        </div>
                      ) : (
                        <div className={css.stillProcessing}>
                          <div className={css.stillProcessingText}>
                            {surface && imageStillProcessingText(surface)}
                            <br />
                            <br />
                            Please check back later.
                          </div>
                          <img
                            src={gears}
                            className={css.stillProcessingImage}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <TransformWrapper
                      positionX={positionX}
                      positionY={positionY}
                      defaultPositionX={positionX}
                      defaultPositionY={positionY}
                      scale={scale}
                      onZoomChange={update}
                      onPanning={update}
                      onPanningStop={update}
                      wheel={{ step: 13 }}
                      doubleClick={{ disabled: true }}
                      reset={{ disabled: true }}
                      pan={{ paddingSize: 0, velocityBaseTime: 0 }}
                      scalePadding={{ disabled: true }}
                      className={css.wrapper}
                      options={{ minScale: minScale, limitToBounds: false }}
                    >
                      <TransformComponent>
                        <img
                          style={imageStyle}
                          key={surface}
                          src={
                            rightScan &&
                            surface &&
                            rightScan.surfaces[surface].image_url
                          }
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </div>
  )
}

export default ScansList
