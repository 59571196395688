import React from "react"
import { FilterOutlined, WarningOutlined } from "@ant-design/icons"

// Categorize image_diameter float into English frame size.
const friendlyImageDiameter = (image_diameter) => {
  if (image_diameter >= 43) return "Full Frame"
  if (image_diameter >= 30) return "Super 35"
  if (image_diameter >= 15) return "Super 16"
  return ""
}

const lensSupported = (lens) => {
  // We currently only support lenses of focal length 20mm and above.
  return lens.supported !== false && lens.focal_length_mm >= 20
}

// Add leaf to lens tree.
const addLeaf = (lens, key, field) => {
  return {
    title: lens.title,
    make: lens.make,
    model: lens.model,
    focal_length_mm: lens.focal_length_mm,
    calibrated_front_rear: lens.calibrated_front_rear,
    fstop: lens.fstop,
    tstop: lens.tstop,
    length: lens.length,
    image_diameter: lens.image_diameter,
    supported: lensSupported(lens),
    type_id: lens.type_id,
    lenses: lens.lenses,
    isLeaf: true,
    value: key,
    key: key,
    field: field,
    className: "css.treeNode",
    icon: !lensSupported(lens) ? (
      <WarningOutlined />
    ) : (
      <FilterOutlined rotate={90} />
    ),
  }
}

// Generate array of child leaf nodes and child sub-trees.
const mungeChildren = (
  make,
  model,
  makeCount,
  modelCount,
  keys,
  lensMappings
) => {
  let children = []
  keys.forEach((focal_length_mm, focalLengthCount) => {
    let flArray = lensMappings[make][model][focal_length_mm]
    let f1UniqueLen = [...new Set(flArray.map((l) => l.image_diameter))].length
    // 'Focal_Length_MM' leaf.
    if (flArray.length === 1) {
      let lens = lensMappings[make][model][focal_length_mm][0]
      lens.title = `${focal_length_mm}mm`
      const leaf = addLeaf(
        lens,
        `0-${makeCount}-${modelCount}-${focalLengthCount}`,
        "focalLength"
      )
      children.push(leaf)
    }
    // `Focal_length_MM Tstop` leaves.
    else if (f1UniqueLen === 1) {
      flArray.forEach((lens, index) => {
        lens.title = `${lens.focal_length_mm}mm T${lens.tstop}`
        const leaf = addLeaf(
          lens,
          `0-${makeCount}-${modelCount}-${focalLengthCount}.${index}`,
          "focalLength"
        )
        children.push(leaf)
      })
    }
    // 'Focal_Length_MM' subtree.
    else {
      const subtree = {
        title: `${focal_length_mm}mm`,
        value: `0-${makeCount}-${modelCount}-${focalLengthCount}`,
        key: `0-${makeCount}-${modelCount}-${focalLengthCount}`,
        field: "focalLength",
        isLeaf: false,
        children: flArray.map((lens, index) => {
          return addLeaf(
            lens,
            `0-${makeCount}-${modelCount}-${focalLengthCount}-${index}`,
            "imageDiameter"
          )
        }),
      }
      children.push(subtree)
    }
  })
  return children
}

// Generate tree from firestore.js::getLensTypes documents:
//   make -> model -> focal_length_mm (tstop) (-> image_diameter).
const mungeTreeData = (lensTypes) => {
  const treeData = []
  let lensMappings = {}

  lensTypes.forEach((lensType) => {
    if (lensMappings[lensType.make] === undefined) {
      lensMappings[lensType.make] = {}
    }
    if (lensMappings[lensType.make][lensType.model] === undefined) {
      lensMappings[lensType.make][lensType.model] = {}
    }
    if (
      lensMappings[lensType.make][lensType.model][lensType.focal_length_mm] ==
      undefined
    ) {
      lensMappings[lensType.make][lensType.model][lensType.focal_length_mm] = []
    }
    lensMappings[lensType.make][lensType.model][lensType.focal_length_mm].push({
      title: `${friendlyImageDiameter(lensType.image_diameter)}`,
      make: lensType.make,
      model: lensType.model,
      focal_length_mm: lensType.focal_length_mm,
      calibrated_front_rear: lensType.calibrated_front_rear,
      fstop: lensType.fstop,
      tstop: lensType.tstop,
      length: lensType.length,
      image_diameter: lensType.image_diameter,
      supported: lensType.supported,
      type_id: lensType.type_id,
      lenses: lensType.lenses,
    })
  })
  let makeCount = 0
  for (const make in lensMappings) {
    // 'Make' tree.
    treeData.push({
      title: make,
      value: `0-${makeCount}`,
      key: `0-${makeCount}`,
      field: "make",
      isLeaf: false,
      children: Object.keys(lensMappings[make]).map((model, modelCount) => {
        // 'Model' subtree.
        return {
          title: model,
          value: `0-${makeCount}-${modelCount}`,
          key: `0-${makeCount}-${modelCount}`,
          field: "model",
          isLeaf: false,
          children: mungeChildren(
            make,
            model,
            makeCount,
            modelCount,
            // Sort by focal_length_mm float, not key string.
            Object.keys(lensMappings[make][model]).sort(
              (x, y) => parseFloat(x) - parseFloat(y)
            ),
            lensMappings
          ),
        }
      }),
    })
    makeCount++
  }
  return treeData
}

export default mungeTreeData
