import React, { useState, useEffect } from "react"
import { useGlobalState } from "../../../state"
import { Link, useLocation } from "react-router-dom"
import { Menu, Icon, Layout } from "antd"
import { HistoryOutlined } from "@ant-design/icons"
import tracisLogo from "../../../images/tracis_horiz_white_sm.png"
import "./index.css"

export default function LeftNav() {
  const [state, dispatch] = useGlobalState()
  const [currentLocation, setCurrentLocation] = useState("")
  const { Sider } = Layout
  const { Item } = Menu
  const location = useLocation()

  useEffect(() => {
    const rootPath = location.pathname.split("/")[1]
    setCurrentLocation(`/${rootPath}`)
  }, [location.pathname])

  const toggleCollapsed = () => {
    dispatch({ type: "SET_NAV_COLLAPSED", collapsed: !state.collapsed })
  }

  // Fix for iOS double-tap 'feature' -- single tap registers as hover.
  const followLink = (event) => {
    event.preventDefault()
    const path = event.target.href
    window.location = path
  }
  return (
    <Sider
      collapsed={state.collapsed}
      style={{
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div className="tracisLogo" onClick={toggleCollapsed}>
        <img src={tracisLogo} alt="Tracis Logo" height={36} />
      </div>
      <Menu selectedKeys={[currentLocation]} mode="inline">
        <Item key="/inventory">
          <Link to="/inventory" onTouchEnd={followLink}>
            <Icon type="search" />
            <span>Inventory</span>
          </Link>
        </Item>
        <Item key="/scan">
          <Link to="/scan" onTouchEnd={followLink}>
            <Icon type="scan" />
            <span>Scan</span>
          </Link>
        </Item>
        <Item key="/scan_history">
          <Link to="/scan_history">
            <HistoryOutlined />
            <span>Scan History</span>
          </Link>
        </Item>
        <Item key="/settings" id="forceSettingToBottomOfLeftNav">
          <Link to="/settings" onTouchEnd={followLink}>
            <Icon type="setting" />
            <span>Settings</span>
          </Link>
        </Item>
      </Menu>
    </Sider>
  )
}
