// In order for the scan images to take up the entire screen real estate we need to
// use Javascript to find the browser window width and height, then use those values
// in calculating inline styles for image width/height and viewer window width/height.
const getInlineStyles = (width, height, compareScans) => {
  const menuWidth = 79 // Unchanging width of the left nav in pixels.
  const titleWidthPadding = 148
  const iPadBreakpoint = 769 // iPad is 768x1024.
  const minimumDimension = Math.min(width, height)
  // Amount to offset image title in pixels. Numbers are trial and error -- iPad
  // requires less padding than desktop.
  const titleHeightPadding = minimumDimension < iPadBreakpoint ? 58 : 168
  const dividerThickness = 3
  let imageInlineStyling = {}
  let windowInlineStyling = {}
  let titleInlineStyling = {}
  let baselineTitleStyle = {
    position: "absolute",
    zIndex: "100",
    width: "100%",
  }
  const fullWidth = width - menuWidth // Full container width in pixels.
  const fullWidthInPixels = `${fullWidth}px`
  const halfWidth = (width - menuWidth - dividerThickness) / 2 // Half container width minus divider in pixels.
  const halfWidthInPixels = `${halfWidth}px`
  const fullHeight = height // Full container height in pixels.
  const fullHeightInPixels = `${fullHeight}px`
  const halfHeight = (height - dividerThickness) / 2 // Half container height minus divider in pixels.
  const halfHeightInPixels = `${halfHeight}px`
  if (compareScans) {
    // View scans side-by-side.
    if (width > height) {
      imageInlineStyling = {
        width: fullWidthInPixels,
        height: fullWidthInPixels,
      }
      windowInlineStyling = {
        width: halfWidthInPixels,
        height: fullHeightInPixels,
        overflow: "hidden",
      }
      titleInlineStyling = {
        ...baselineTitleStyle,
        top: `${height - titleHeightPadding}px`,
        left: `${halfWidth - titleWidthPadding}px`,
      }
    }
    // View scans stacked.
    else {
      imageInlineStyling = {
        height: fullHeightInPixels,
        width: fullHeightInPixels,
      }
      windowInlineStyling = {
        height: halfHeightInPixels,
        width: fullWidthInPixels,
        overflow: "hidden",
      }
      titleInlineStyling = {
        ...baselineTitleStyle,
        top: `${halfHeight - titleHeightPadding}px`,
        left: `${fullWidth - titleWidthPadding}px`,
      }
    }
  } else {
    // View image at its largest dimension.
    titleInlineStyling = {
      ...baselineTitleStyle,
      top: `${fullHeight - titleHeightPadding}px`,
      left: `${fullWidth - titleWidthPadding}px`,
    }
    if (height >= width) {
      imageInlineStyling = {
        height: fullHeightInPixels,
      }
      windowInlineStyling = {
        height: fullHeightInPixels,
        width: fullWidthInPixels,
        overflow: "hidden",
      }
    } else {
      imageInlineStyling = { width: fullWidthInPixels }
      windowInlineStyling = {
        width: fullWidthInPixels,
        height: fullHeightInPixels,
        overflow: "hidden",
      }
    }
  }
  // Set image minimum scale to be proportional to its smallest dimension.

  return {
    image: imageInlineStyling,
    window: windowInlineStyling,
    title: titleInlineStyling,
  }
}

export default getInlineStyles
