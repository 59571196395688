import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import { INSTRUCTION, DEMO_LENS, COLLECTION, SCAN_STATUS } from "./constants"
export const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
}

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

const arraysEqual = (array1, array2) => {
  if (array1 === undefined && array2 !== undefined) return false
  if (array2 === undefined && array1 !== undefined) return false
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  )
}

// Dynamically fetch app information from the `/__/...` endpoint, which is
// Firebase Hosting's reserved URL: https://firebase.google.com/docs/hosting/reserved-urls
// This means we don't need to change our code to deploy to different projects.
const asyncApp = fetch("/__/firebase/init.json").then(async (response) => {
  const json = await response.json()
  return firebase.initializeApp(json)
})

export const getDb = async () => {
  const app = await asyncApp
  return firebase.firestore(app)
}

export const getStorage = async () => {
  const app = await asyncApp
  return app.storage()
}

export const getAuth = async () => {
  const app = await asyncApp
  const appAuth = app.auth()
  return appAuth
}

const aliasLenses = (lenses) => {
  let aliasedLenses = []
  lenses.forEach((lens) => {
    if (lens.make === "ARRI") {
      aliasedLenses.push({ ...lens, make: "Zeiss" })
    }
    if (lens.make === "Zeiss" && lens.model.startsWith("Super Speed")) {
      let aliasModel = lens.model.replace("Super Speed", "High Speed")
      aliasedLenses.push({ ...lens, model: aliasModel })
    }
    if (lens.make === "Leitz / Leica") {
      aliasedLenses.push({ ...lens, make: "Leitz" })
      aliasedLenses.push({ ...lens, make: "Leica" })
      // Since this is pushed as Leitz and Leica we don't need to keep it.
      return
    }
    if (lens.make === "Leica") {
      aliasedLenses.push({ ...lens, make: "Leitz" })
    }
    if (lens.make === "Leitz") {
      aliasedLenses.push({ ...lens, make: "Leica" })
    }
    aliasedLenses.push(lens)
  })
  return aliasedLenses
}

const describeLensModel = (lens) => {
  if (lens === undefined) return {}
  lens.model = lens.housing_make
    ? `${lens.model}, ${lens.housing_make} housed`
    : lens.model
  lens.focal_length_mm = lens.focal_length_max
    ? `${lens.focal_length_mm}-${lens.focal_length_max}`
    : lens.focal_length_mm
  return lens
}

// Sort lenses by make, model, focal length, image diameter, then t-stop.
const sortLenses = (lenses) => {
  return lenses.sort((lens1, lens2) => {
    if (lens1.make > lens2.make) return 1
    if (lens1.make < lens2.make) return -1
    if (lens1.model > lens2.model) return 1
    if (lens1.model < lens2.model) return -1
    if (parseFloat(lens1.focal_length_mm) > parseFloat(lens2.focal_length_mm))
      return 1
    if (parseFloat(lens1.focal_length_mm) < parseFloat(lens2.focal_length_mm))
      return -1
    if (parseFloat(lens1.image_diameter) > parseFloat(lens2.image_diameter))
      return 1
    if (parseFloat(lens1.image_diameter) < parseFloat(lens2.image_diameter))
      return -1
    if (parseFloat(lens1.tstop) > parseFloat(lens2.tstop)) return 1
    if (parseFloat(lens1.tstop) < parseFloat(lens2.tstop)) return -1
    return 0
  })
}

export const firestore = {
  getLensTypes: async () => {
    const db = await getDb()
    const snapshot = await db
      .collection(COLLECTION.LENS_TYPES)
      .where("visible", "==", true)
      .get()
    let data = snapshot.docs.map((doc) => {
      const doc_data = doc.data()
      return describeLensModel(doc_data)
    })
    data = aliasLenses(data)
    // Sort by Make, then Model, then Focal Length, then Image Diameter, then T-Stop
    return sortLenses(data)
  },
  startScanning: async (orgId, scannerId, lens, user, scanID) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .update({
        user_scan_params: {
          lens: { ...lens, supported: true },
          scan_id: scanID,
          user_id: user.uid,
          org_id: orgId,
        },
        instruction: INSTRUCTION.START,
        author_email: user.email,
        percent_complete: 0,
      })
  },
  startDemo: async (orgId, scannerId, author, scanID) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .update({
        user_scan_params: {
          lens: DEMO_LENS,
          quality: 7,
          save_data: false,
          org_id: orgId,
          scan_id: scanID,
        },
        instruction: INSTRUCTION.START_DEMO,
        author_email: author,
        percent_complete: 0,
        scan_id: scanID,
      })
  },
  stopScanning: async (orgId, scannerId) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .update({
        instruction: INSTRUCTION.STOP,
        current_scan: "stopping",
      })
  },
  onScannerStatusChange: async (orgId, scannerId, onStatusChange) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .onSnapshot((snapshot) => {
        onStatusChange({ ...snapshot.data(), id: snapshot.id })
      })
  },
  onScanStatusChange: async (orgId, scanId, onStatusChange) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANS)
      .doc(scanId)
      .onSnapshot((snapshot) =>
        onStatusChange({ ...snapshot.data(), id: snapshot.id })
      )
  },
  onOrgsForUser: async (uuid, onChange) => {
    console.debug(`Fetching user orgs for user ${uuid}...`)
    const db = await getDb()
    return db
      .collection(COLLECTION.USERS)
      .doc(uuid)
      .onSnapshot(async (snapshot) => {
        let updatedOrgs = []
        if (snapshot.exists) updatedOrgs = snapshot.data().organizations
        // Compare the orgs to the list of organizations currently in our JWT.
        // If lists don't match, re-request the token until they do match.
        let retries = 0
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (retries > 5) {
            throw `Token still not refreshed after ${retries} retries; giving up.`
          }
          // Get the JWT as currently stored in memory (doesn't update the token).
          const auth = await getAuth()
          const currentToken = await auth.currentUser.getIdTokenResult()
          const currentOrgs = currentToken.claims.organizations
          if (arraysEqual(currentOrgs, updatedOrgs)) break // Awesome, Firestore matches the JWT.
          // Firestore doesn't match Firebase Auth's JWT.
          console.info(
            `Organizations in Firestore don't match Auth: '${currentOrgs}' 
              vs. '${updatedOrgs}.' Refreshing token...`
          )
          if (retries++ > 0) {
            await sleep(5000) // Wait 5 seconds to give Auth time to catch up to Firestore.
          }
          await auth.currentUser.getIdToken(true) // Refresh token.
        }
        // With Firestore and Auth in sync, we can tell the user the happy news.
        console.info(
          `User has access to organizations: ${JSON.stringify(updatedOrgs)}`
        )
        onChange(updatedOrgs)
      })
  },
  onScannersForOrg: async (orgId, onChange) => {
    const db = await getDb()
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .onSnapshot((snapshot) => {
        const scanners = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        onChange(scanners)
      })
  },
  addLens: async (lens) => {
    const db = await getDb()
    return await db.collection(COLLECTION.LENS_TYPES).add(lens)
  },
  updateLens: async (tracisId, lens) => {
    const db = await getDb()
    await db.collection(COLLECTION.LENS_TYPES).doc(tracisId).update({
      focal_length_mm: lens.focal_length_mm,
      image_diameter: lens.image_diameter,
      length: lens.length,
      tstop: lens.tstop,
      mount: lens.mount,
    })
    return db.collection(COLLECTION.LENS_TYPES).doc(tracisId)
  },
  addSerialNumber: async (serialNumber, lensTypeID, orgID) => {
    const db = await getDb()
    const lensTypeRef = db.collection(COLLECTION.LENS_TYPES).doc(lensTypeID)
    const lens = {
      serial_number: serialNumber,
      lens_type_ref: lensTypeRef,
    }
    const lensRef = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgID)
      .collection(COLLECTION.LENSES)
      .add(lens)
    const lensData = await lensRef.get()
    return { id: lensData.id, ...lensData.data() }
  },
  getSerialNumbers: async (lensTypeID, orgID) => {
    const db = await getDb()
    const lensTypeRef = db.collection(COLLECTION.LENS_TYPES).doc(lensTypeID)
    const serialNumberDocs = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgID)
      .collection(COLLECTION.LENSES)
      .where("lens_type_ref", "==", lensTypeRef)
      .get()
    return serialNumberDocs.docs.map((doc) => {
      return { id: doc.id, ...doc.data() }
    })
  },
  getLensByTypeID: async (lensTypeID) => {
    const db = await getDb()
    const snapshot = await db
      .collection(COLLECTION.LENS_TYPES)
      .doc(lensTypeID)
      .get()
    const serialNumberDocs = await db
      .collection(COLLECTION.LENS_TYPES)
      .doc(lensTypeID)
      .collection(COLLECTION.LENSES)
      .get()
    const serialNumbers = serialNumberDocs.docs.map((doc) => {
      let data = doc.data()
      return data.serial_number
    })
    return {
      ...snapshot.data(),
      typeID: snapshot.id,
      serialNumbers: serialNumbers,
    }
  },
  getLens: async (orgId, lensId) => {
    const db = await getDb()
    const lensDoc = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .get()

    const lensData = lensDoc.data()
    const lensTypeDoc = await db
      .collection(COLLECTION.LENS_TYPES)
      .doc(lensData.lens_type_ref.id)
      .get()

    return {
      ...lensTypeDoc.data(),
      typeID: lensData.lens_type_ref.id,
      serial_number: lensData.serial_number,
      lens_id: lensId,
    }
  },
  getScanner: async (orgId, scannerId) => {
    const db = await getDb()
    const scanner = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .get()
    return { ...scanner.data(), id: scanner.id }
  },
  getLenses: async (orgId) => {
    const db = await getDb()
    const snapshot = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .get()

    let lensesByType = {}

    let lenses = snapshot.docs.map((doc) => {
      return { lens_id: doc.id, ...doc.data() }
    })
    lenses = lenses.filter((lens) => lens.lens_type_ref !== undefined)
    let lensTypePromises = lenses.map((lens) => lens.lens_type_ref.get())
    const lensTypeData = await Promise.all(lensTypePromises)

    lenses.forEach((lens, i) => {
      if (lensesByType[lens.lens_type_ref.id] !== undefined) {
        lensesByType[lens.lens_type_ref.id].lenses.push(lens)
      } else {
        lensesByType[lens.lens_type_ref.id] = {
          ...describeLensModel(lensTypeData[i].data()),
          lenses: [lens],
        }
      }
    })
    lensesByType = Object.values(lensesByType)
    lensesByType = lensesByType.filter(
      (lens) => lens.surfaces !== undefined && lens.surfaces.length > 0
    )

    return sortLenses(Object.values(lensesByType))
  },
  getScansByLens: async (orgId, lensId) => {
    const db = await getDb()
    const cloudStorage = await getStorage()
    const lensRef = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
    const scansSnapshot = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANS)
      .where("lens_ref", "==", lensRef)
      .where("status", "in", [
        SCAN_STATUS.PROCESSED,
        SCAN_STATUS.PARTIALLY_PROCESSED,
      ])
      .get()

    let scans = scansSnapshot.docs.map((doc) => doc.data())
    scans = scans.filter((s) => s.surfaces !== undefined && s.hidden !== true)
    let image_promises = []
    for (let scan of scans) {
      // Get Cloud Storage URLs.
      for (let [surfaceName, surface] of Object.entries(scan.surfaces)) {
        if (surface.image !== undefined) {
          // Generate Cloud Storage URL from image path.
          let gsReference = cloudStorage.ref(surface.image)
          image_promises.push(gsReference.getDownloadURL())
        } else {
          // No image (still_processing).
          image_promises.push(undefined)
        }
      }
    }

    // Generate access tokens for images, create URLs with tokens.
    let surface_images = await Promise.all(
      image_promises.map((gs) =>
        gs ? gs.catch((error) => undefined) : undefined
      )
    )

    for (let scan of scans) {
      let surfaces = Object.values(scan.surfaces)
      for (let i = 0; i < surfaces.length; i++) {
        // Associate returned URL with correct surface.
        surfaces[i].image_url = surface_images.shift()
      }
    }
    return scans.sort(
      (scanA, scanB) => scanB.timestamp.seconds - scanA.timestamp.seconds
    )
  },
  // Optional filter arguments:
  //   - date: Array of two UNIX seconds values chosen from dateFilter RangePicker.
  //   - userId: Firestore user ID string chosen from userFilter Select.
  //   - status: Status ("BAD", "COMPLETED, etc.) chosen from statusFilter Select.
  //   - scannerId:  Firestore scanner ID string chosen from scannerFilter Select.
  getScans: async (orgId, date = [], userId, status, scannerId) => {
    const db = await getDb()
    const cloudStorage = await getStorage()
    let scans_query = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANS)

    if (date.length === 2 && !isNaN(date[0]) && !isNaN(date[1])) {
      const begin = new Date(date[0] * 1000)
      const end = new Date(date[1] * 1000)
      scans_query = scans_query
        .where("timestamp", ">=", begin)
        .where("timestamp", "<=", end)
    }

    if (userId) {
      const user_ref = db.collection(COLLECTION.USERS).doc(userId)
      scans_query = scans_query.where("user_ref", "==", user_ref)
    }

    if (status) {
      scans_query = scans_query.where("status", "==", status)
    }

    if (scannerId) {
      const scanner_ref = db
        .collection(COLLECTION.ORGANIZATIONS)
        .doc(orgId)
        .collection(COLLECTION.SCANNERS)
        .doc(scannerId)
      scans_query = scans_query.where("scanner_ref", "==", scanner_ref)
    }

    const snapshot = await scans_query.orderBy("timestamp", "desc").get()

    let scansData = []
    let lensTypePromises = []
    let lensPromises = []
    let scannerPromises = []
    let userPromises = []
    let downloadPromises = []
    let scans = snapshot.docs.map((doc) => doc.data())
    scans = scans.filter(
      (scan) =>
        scan.user_ref !== null &&
        scan.user_ref !== undefined &&
        scan.lens_ref !== null &&
        scan.lens_ref !== undefined &&
        scan.scanner_ref !== null &&
        scan.scanner_ref !== undefined &&
        scan.hidden !== true
    )
    for (var i = 0; i < scans.length; i++) {
      lensTypePromises.push(scans[i].lens_type_ref.get())
      lensPromises.push(scans[i].lens_ref.get())
      scannerPromises.push(scans[i].scanner_ref.get())
      userPromises.push(scans[i].user_ref.get())
      if (scans[i].report_path === undefined) {
        downloadPromises.push(null)
      } else {
        const gsReference = cloudStorage.ref(scans[i].report_path)
        downloadPromises.push(gsReference.getDownloadURL())
      }
    }

    lensTypePromises = await Promise.all(lensTypePromises) // Parallel Promise fetching.
    lensPromises = await Promise.all(lensPromises)
    scannerPromises = await Promise.all(scannerPromises)
    userPromises = await Promise.all(userPromises)
    downloadPromises = await Promise.all(
      downloadPromises.map((gs) =>
        gs ? gs.catch((error) => undefined) : undefined
      )
    )

    for (var i = 0; i < scans.length; i += 1) {
      let scan = scans[i]
      scan.lens = lensTypePromises[i].data()
      scan.lens.serialNumber = lensPromises[i].data().serial_number

      scan.scanner = {
        ...scannerPromises[i].data(),
        doc_id: scannerPromises[i].id,
      }
      scan.user = { ...userPromises[i].data(), doc_id: userPromises[i].id }
      scan.downloadLink = downloadPromises[i]
      scansData.push(scan)
    }
    return scansData
  },
  onActiveScansChange: async (orgId, onChange) => {
    const activeStatuses = [SCAN_STATUS.STARTED, SCAN_STATUS.UPLOADING]
    const db = await getDb()
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANS)
      .where("status", "in", activeStatuses)
      .onSnapshot((snapshot) => {
        const scans = snapshot.docs.map((doc) => {
          const docData = doc.data()
          const scanner_id = docData.scanner_ref.id
          return { ...docData, scanner_id: scanner_id }
        })
        onChange(scans)
      })
  },
  startService: async (orgId, scannerId, author) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .update({
        instruction: INSTRUCTION.START_SERVICE,
        author_email: author,
      })
  },
  onServiceChange: async (orgId, scannerId, onChange) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .onSnapshot((snapshot) => {
        const scanner = snapshot.data()
        onChange({ ...scanner, id: scanner.id })
      })
  },
  stopService: async (orgId, scannerId) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .update({
        instruction: INSTRUCTION.STOP_SERVICE,
      })
  },
}
