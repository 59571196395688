import React, { useEffect, useState } from "react"
import { Input, Button, Modal, Divider } from "antd"
import LensTree from "../../common/LensTree"
import Header from "../Header"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import { firestore as db } from "../../../state/firebase"
import { STEPS } from "../../../state/constants"
import mungeTreeData from "../../../helpers/mungeTreeData"

export default function SelectLens({ setStep }) {
  const [state, dispatch] = useGlobalState()
  const [filteredLenses, setFilteredLenses] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([]) // Expand if make/model match.
  const [unsupportedModalVisible, setUnsupportedModalVisible] = useState(false)

  useEffect(() => {
    if (state.lensTypes.length) {
      setFilteredLenses(mungeTreeData(state.lensTypes))
      return
    }
    db.getLensTypes().then((docs) => {
      dispatch({ type: "ALL_LENS_TYPES", lensTypes: docs })
      setFilteredLenses(mungeTreeData(docs))
    })
  }, [dispatch, mungeTreeData])

  const handleChange = (event) => {
    const lenses = state.lensTypes.filter((lens) => {
      const searchInput = event.target.value.toLowerCase()
      return (
        lens.make &&
        `${lens.make.toLowerCase()} ${lens.model.toLowerCase()}`.includes(
          searchInput
        )
      )
    })
    const treeLenses = mungeTreeData(lenses)
    setFilteredLenses(treeLenses)
    // Expand if only one make found.
    if (lenses.length === 1 || treeLenses.length === 1) {
      setExpandedKeys([treeLenses[0].key])
      if (treeLenses[0].children.length === 1) {
        setExpandedKeys([treeLenses[0].key, treeLenses[0].children[0].key])
      }
    }
    return lenses
  }

  const handleClick = () => {
    if (state.currentScanLens.supported === false) {
      warning()
      setUnsupportedModalVisible(true)
      return
    }
    if (state.currentScanLens.calibrated_front_rear !== true) {
      setStep(STEPS.ADD_LENS)
      return
    }
    setStep(STEPS.SELECT_SERIAL_NUMBER)
  }

  const warning = () => {
    Modal.warning({
      title: "Lens Not Supported",
      centered: true,
      closable: false,
      onOk: handleDismissModal,
      visible: unsupportedModalVisible,
      okText: "Select Another Lens",
      okButtonProps: {
        ghost: true,
      },
      content: (
        <div>
          <p>
            {`We apologize but the ${state.currentScanLens.make}
            ${state.currentScanLens.model} 
            ${state.currentScanLens.focal_length_mm}mm is not supported by our
            system at this time.`}
          </p>
          <p>Please try again with another lens.</p>
        </div>
      ),
      footer: [
        <Button key="submit" type="primary" onClick={handleDismissModal} />,
      ],
    })
  }

  const handleDismissModal = () => {
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: null })
    setUnsupportedModalVisible(false)
  }

  const handleAddLensClick = () => {
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: null })
    setStep(STEPS.ADD_LENS)
  }

  return (
    <div className={css.container}>
      <Header>
        Select A Lens
        <br />
        <div className={css.subHeader}>
          Powered by{" "}
          <a
            href="https://www.lenserapp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={css.lenserLogo} src="lenser.svg" />
          </a>
        </div>
      </Header>
      <div className={css.grey}>Search</div>
      <div className={css.headerContainer}>
        <Input onChange={handleChange} placeholder="Make or Model" />
        <span className={css.or}>or</span>
        <Button type="primary" ghost onClick={handleAddLensClick}>
          Add Lens
        </Button>
      </div>
      <div className={css.lensTreeBody}>
        {expandedKeys.length > 0 ? (
          <LensTree
            treeData={filteredLenses}
            autoExpandParent={true}
            expandedKeys={expandedKeys}
            showLine={false}
            showIcon={true}
            switcherIcon={<div></div>}
          />
        ) : (
          <LensTree
            treeData={filteredLenses}
            autoExpandParent={true}
            showLine={false}
            showIcon={true}
            switcherIcon={<div></div>}
          />
        )}
      </div>
      <Divider
        style={{
          background: "#34383b",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      ></Divider>
      <div className="continue-container">
        <Button
          type="primary"
          ghost
          onClick={handleClick}
          className="continue"
          disabled={!state.currentScanLens}
          block
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
