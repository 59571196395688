import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import css from "./index.module.css"
import ScansList from "./ScansList"
import Default from "./Default"
import { useGlobalState } from "../../state"
import { firestore as db } from "../../state/firebase"
import { Button, Icon, Layout, Select, Tooltip } from "antd"
import LensTree from "../common/LensTree"
import mungeTreeData from "../../helpers/mungeTreeData"
import { generateDateTimeString } from "../../helpers/date"
import cx from "classnames"
import Loading from "../common/Loading"

export default function Inventory({ ...props }) {
  const [state, dispatch] = useGlobalState()
  const [lenses, setLenses] = useState({})
  const [scans, setScans] = useState([])
  const [leftScan, setLeftScan] = useState(undefined)
  const [rightScan, setRightScan] = useState(undefined)
  const [surface, setSurface] = useState(undefined)
  const [surfaces, setSurfaces] = useState([])
  const [collapsed, setCollapsed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [compareScans, setCompareScans] = useState(false)
  const [lensTitle, setLensTitle] = useState(null)
  const [url, setUrl] = useState(new URL(window.location))
  const [history, _setHistory] = useState(useHistory())
  const { Option } = Select
  const { Content, Header, Sider } = Layout

  // Open/close the lens tree sider.
  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  // Called when the user toggles between `single` and `compare` mode.
  // Parameters:
  //   `compare`: a Boolean that indicates whether we've switched to compare mode or not.
  const handleCompareFilterChange = (compare) => {
    const leftScanIndex = scans.indexOf(leftScan)

    if (compare === compareScans) return // Already in the right mode.
    // Determine whether we're entering compare mode for the first time, in
    // which case there is no `rightScan` set yet.
    if (compare) {
      // Move current scan to the right and show next-earliest scan on left.
      setLeftScan(scans[leftScanIndex + 1])
      setRightScan(scans[leftScanIndex])
    } else {
      // Keep right scan as the current scan in switch to single mode.
      setLeftScan(rightScan)
    }
    setCompareScans(compare)
  }

  // Called when the user changes scan date in single mode or first scan date in
  // compare mode.
  // Parameters:
  //   `leftScanId`: ID string of target scan (leftScan._id).
  const handleLeftDateFilterChange = (leftScanId) => {
    const newScan = scans.find((s) => s._id === leftScanId)
    setLeftScan(newScan)
  }

  // Called when the user changes second scan date in  compare mode.
  // Parameters:
  //   `rightScanId`: ID string of target scan (rightScan._id).
  const handleRightDateFilterChange = (rightScan) => {
    const newScan = scans.find((s) => s._id === rightScan)
    setRightScan(newScan)
    // Change left date if a right date selection causes it to be earlier
    // than the current left date.
    if (newScan.timestamp.seconds <= leftScan.timestamp.seconds) {
      const rightScanIndex = scans.indexOf(newScan)
      setLeftScan(scans[rightScanIndex + 1])
    }
  }

  // Called when the user changes surface e-value.
  // Parameters:
  //   `eValue`: e-value float of target surface.
  const handleSurfaceFilterChange = (eValue) => {
    setSurface(eValue)
  }

  // Show message there are not two scans to compare if user attempts to
  // switch to compare mode.
  const showDisabledTooltip =
    !compareScans &&
    (scans.length <= 1 || scans.indexOf(leftScan) === scans.length - 1)

  // Customize tooltip message based on reason button is disabled.
  const disabledTooltipMessage =
    scans.length === 1
      ? "A lens must have at least 2 scans to compare."
      : "No earlier scans to compare."

  const compareToggle = (
    <Button.Group key="compareFilter" className={css.compareFilter}>
      <Tooltip
        placement="left"
        title={disabledTooltipMessage}
        overlayClassName={showDisabledTooltip ? "" : css.hidden}
      >
        <Button
          type="primary"
          onClick={() => handleCompareFilterChange(true)}
          ghost={!compareScans}
          disabled={showDisabledTooltip}
        >
          Compare
        </Button>
      </Tooltip>
      <Button
        type="primary"
        onClick={() => handleCompareFilterChange(false)}
        ghost={compareScans}
        style={{ borderColor: "#A288FF" }}
      >
        Single
      </Button>
    </Button.Group>
  )

  const leftDateSelect = (
    <Select
      onChange={handleLeftDateFilterChange}
      placeholder="Scan Date"
      allowClear={false}
      className={css.dateFilter}
      dropdownMatchSelectWidth={false}
      key="dateFilterA"
      value={leftScan && leftScan._id}
    >
      {scans.map((scan) => {
        // Disable dates later than or equal to the right scan date so left date
        // is always earlier than the right.
        return compareScans &&
          scan.timestamp.seconds >= rightScan.timestamp.seconds ? (
          <Option
            key={scan._id}
            value={scan._id}
            className={css.inventoryOption}
            disabled
          >
            {" "}
            <Tooltip
              placement="left"
              title="Select an older scan to compare against right scan."
            >
              {generateDateTimeString(scan.timestamp.seconds)}
            </Tooltip>
          </Option>
        ) : (
          <Option
            key={scan._id}
            value={scan._id}
            className={css.inventoryOption}
          >
            {generateDateTimeString(scan.timestamp.seconds)}
          </Option>
        )
      })}
    </Select>
  )

  const rightDateSelect = (
    <Select
      onChange={handleRightDateFilterChange}
      placeholder="Scan Date"
      allowClear={false}
      className={css.dateFilter}
      dropdownMatchSelectWidth={false}
      key="dateFilterB"
      value={rightScan && rightScan._id}
    >
      {scans.map((scan, i) => {
        // Disable earliest option so right scan date is always later than left.
        return i === scans.length - 1 ? (
          <Option
            key={scan._id}
            value={scan._id}
            className={css.inventoryOption}
            disabled
          >
            <Tooltip
              placement="left"
              title="Select a newer scan to compare against left scan."
            >
              {generateDateTimeString(scan.timestamp.seconds)}
            </Tooltip>
          </Option>
        ) : (
          <Option
            key={scan._id}
            value={scan._id}
            className={css.inventoryOption}
          >
            {generateDateTimeString(scan.timestamp.seconds)}
          </Option>
        )
      })}
    </Select>
  )

  const surfaceSelect = surfaces.length > 0 && (
    <Select
      onChange={handleSurfaceFilterChange}
      placeholder="Surface"
      allowClear={false}
      className={css.inventoryFilter}
      dropdownMatchSelectWidth={false}
      key="surfaceFilter"
      defaultValue={surface || surfaces[0]}
    >
      {surfaces.map((surface) => (
        <Option key={surface} value={surface} className={css.inventoryOption}>
          {surface}
        </Option>
      ))}
    </Select>
  )

  const filters = compareScans
    ? [compareToggle, leftDateSelect, rightDateSelect, surfaceSelect]
    : [compareToggle, leftDateSelect, surfaceSelect]

  // Called to find scan inside scans array by timestamp.
  // Parameters:
  //   `seconds`: timestamp.seconds as a string from query parameters.
  const findScanByTimestamp = (seconds, scansArray) => {
    return scansArray.find(
      (scan) => scan.timestamp.seconds === parseInt(seconds)
    )
  }

  // Register browser history listener.
  useEffect(() => {
    history.listen(() => {
      setUrl(new URL(window.location))
      console.log(`You changed the page to: ${window.location.pathname}`)
    })
  }, [])

  // Listen to URL path changes.
  useEffect(() => {
    const pathParts = url.pathname.split("/")
    if (pathParts.length > 2 && state.userOrgIds.length > 0) {
      db.getLens(state.userOrgIds[0], pathParts[2]).then((doc) => {
        dispatch({ type: "SET_INVENTORY_LENS", lens: doc })
      })
    }
    if (pathParts.length <= 2) {
      setCollapsed(false)
      dispatch({ type: "SET_INVENTORY_LENS", lens: undefined })
    }
  }, [url.pathname, state.userOrgIds])

  // Get organization's lenses upon page load.
  useEffect(() => {
    db.onOrgsForUser(props.user.uid, (organizations) => {
      dispatch({ type: "SET_USER_ORGS_IDS", userOrgIds: organizations })
      if (organizations.length > 1) {
        console.error(
          "Only one organization supported right now; using only the first"
        )
      }
      if (organizations.length === 0) return

      db.getLenses(organizations[0]).then((docs) => {
        const lensTree = mungeTreeData(docs)
        setLenses(lensTree)
      })
    }).catch((err) => console.error(err))
  }, [])

  // Listen to lens selection and set lens.
  useEffect(() => {
    if (state.inventoryLens) {
      setLeftScan(undefined)
      setRightScan(undefined)
      const title = (
        <div>
          {state.inventoryLens.make}
          {"  "}
          {state.inventoryLens.model} {state.inventoryLens.focal_length_mm}mm •{" "}
          {state.inventoryLens.serial_number}
        </div>
      )
      setLensTitle(title)
      setSurface(undefined) // Reset selected surface upon lens change.
      setCompareScans(false) // Set/reset to single mode upon lens selection.
      setLoading(true)
      db.getScansByLens(state.userOrgIds[0], state.inventoryLens.lens_id).then(
        (scans) => {
          setScans(scans)
          setLoading(false)
        }
      )
      setCollapsed(true) // Hide lens tree upon lens selection.
    }
  }, [state.inventoryLens, state.userOrgIds])

  useEffect(() => {
    // Parse optional URL query parameters.
    const searchParams = url.searchParams
    const leftDateQuery = searchParams.get("date_a")
    const rightDateQuery = searchParams.get("date_b")
    const surfaceQuery = searchParams.get("surface")
    let internalLeftScan = scans[0]
    let internalRightScan = undefined

    if (scans.length > 0) {
      if (leftDateQuery !== null) {
        // Find leftScan by given "date_a" query parameter.
        const leftScanByDate = findScanByTimestamp(leftDateQuery, scans)
        internalLeftScan = leftScanByDate || internalLeftScan

        if (rightDateQuery !== null) {
          // Find rightScan by given "date_b" query parameter.
          const rightScanByDate = findScanByTimestamp(rightDateQuery, scans)
          internalRightScan = rightScanByDate
        }
      }
      // Set surface if "surface" is a query parameter and given surface is a valid name.
      if (
        surfaceQuery !== null &&
        Object.keys(internalLeftScan.surfaces).includes(surfaceQuery)
      ) {
        setSurface(surfaceQuery)
      } else {
        setSurface(Object.keys(internalLeftScan.surfaces)[0])
      }

      setLeftScan(internalLeftScan)
      setRightScan(internalRightScan)
      // Enable compare mode if left and right scans are given and both exist.
      setCompareScans(internalLeftScan && internalRightScan)
    }
  }, [scans])

  // Create a Set of all surface names present in leftScan and rightScan.
  useEffect(() => {
    let allSurfaces = []
    if (leftScan !== undefined) {
      allSurfaces = allSurfaces.concat(Object.keys(leftScan.surfaces))
    }
    if (rightScan !== undefined) {
      allSurfaces = allSurfaces.concat(Object.keys(rightScan.surfaces))
    }
    allSurfaces = [...new Set(allSurfaces)].sort()
    setSurfaces(allSurfaces)
  }, [leftScan, rightScan])

  useEffect(() => {
    const loaded = state.userOrgIds.length >= 1 && leftScan

    loaded !== state.loaded && dispatch({ type: "SET_LOADED", loaded: loaded })
  }, [state.userOrgIds, leftScan])

  return (
    <div>
      <Layout className={css.inventoryContainer}>
        <div
          className={
            collapsed
              ? css.siderContainer
              : cx(css.siderContainer, css.expanded)
          }
        >
          <Sider
            collapsible
            trigger={null}
            className={css.inventorySider}
            collapsed={collapsed}
            width={400}
            collapsedWidth={56}
          >
            <div className={css.lensTreeContainer}>
              <span>
                <Button
                  onClick={toggleCollapsed}
                  style={{ zIndex: 100, padding: 0 }}
                  type="link"
                >
                  <Icon
                    type={collapsed ? "plus-circle" : "minus-circle"}
                    theme="filled"
                    className={css.toggle}
                  />
                </Button>
              </span>
              {collapsed ? (
                <div className={cx(css.sideways, "fadeIn")}>
                  {state.inventoryLens === undefined
                    ? "Select Lens to Begin"
                    : lensTitle}
                </div>
              ) : lenses.length > 0 ? (
                <div className="fadeIn">
                  <LensTree
                    treeData={lenses}
                    treeType="inventory"
                    autoExpandParent={true}
                    showLine={false}
                    showIcon={true}
                    switcherIcon={<div></div>}
                  />
                </div>
              ) : (
                <Loading title="Lenses" />
              )}
            </div>
          </Sider>
        </div>
        <Layout>
          <div
            className={state.inventoryLens ? css.headerContainer : css.hidden}
            id="filtersHeader"
          >
            <Header className={css.filtersContainer}>
              {filters.map((filter, i) => (
                <div className={css.filterContainer} key={i}>
                  {filter}
                </div>
              ))}
            </Header>
          </div>

          <Content>
            <div className={css.scansListContainer}>
              {state.inventoryLens !== undefined ? (
                <ScansList
                  scans={scans}
                  leftScan={leftScan}
                  rightScan={rightScan}
                  surface={surface}
                  compareScans={compareScans}
                  noScans={scans.length === 0 && !loading}
                />
              ) : (
                <Default />
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}
