import React, { useEffect, useState } from "react"
import { Button, Progress, Typography, Skeleton } from "antd"
import Header from "../Header"
import Lens from "../../common/Lens"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import { firestore as db } from "../../../state/firebase"
import {
  MACHINE,
  CURRENT_SCAN,
  STEPS,
  SCAN_STATUS,
} from "../../../state/constants"
import { LoadingOutlined } from "@ant-design/icons"

export default function Scanning({ setStep }) {
  const handleStop = () => {
    db.stopScanning(state.userOrgIds[0], state.scanner.id)
  }

  const handleRestart = () => {
    dispatch({ type: "START_ANOTHER_SCAN" })
    setStep(STEPS.SELECT_LENS)
  }

  const [state, dispatch] = useGlobalState()
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [scanStatus, setScanStatus] = useState("UPLOADING")
  const [scannerStatus, setScannerStatus] = useState({
    percentComplete: 0,
    status: MACHINE.READY,
    currentScan: CURRENT_SCAN.STARTED,
    currentScanVerbose: "",
  })

  const [progressFeatures, setProgressFeatures] = useState({
    buttonText: "Stop",
    strokeColor: {
      "0%": "#A288FF",
      "99%": "#CB49EF",
    },
    icon: "active",
    headerText: "Scanning!",
    description: "Scan in progress",
    buttonAction: handleStop,
  })

  const onProgressChange = (doc) => {
    if (doc.scan_id !== state.scanID) return
    let currentScan = doc.current_scan.startsWith("error")
      ? CURRENT_SCAN.ERROR
      : doc.current_scan
    let currentProgress = doc.current_scan.startsWith("error")
      ? 100
      : doc.percent_complete
    setProgressFeatures(mapScanToFeatures(currentScan, scanStatus))
    setScannerStatus({
      percentComplete: currentProgress,
      status: doc.status,
      currentScan: currentScan,
      currentScanVerbose: doc.current_scan,
    })
    return setShowSkeleton(false)
  }

  const onScanProgressChange = (doc) => {
    if (doc.id !== state.scanID) return
    if (Object.keys(doc) < 1) return
    if (doc.status !== SCAN_STATUS.STARTED) {
      setProgressFeatures(mapScanToFeatures(CURRENT_SCAN.COMPLETED, doc.status))
    }
    setScanStatus(doc.status)
  }

  const mapScanToFeatures = (status, scanStatus) => {
    switch (status) {
      case CURRENT_SCAN.ERROR:
        return {
          strokeColor: "#cf1322", // Antd red-7.
          buttonText: "Reset",
          icon: "exception",
          headerText: "ERROR",
          description: "Error",
          buttonAction: handleRestart,
        }
      case CURRENT_SCAN.STOPPING:
        return {
          strokeColor: "#8c8c8c", // Antd gray-8.
          buttonText: "Reset",
          icon: "exception",
          headerText: "Stopped",
          description: "Stopping scan...",
          buttonAction: handleRestart,
        }
      case CURRENT_SCAN.STOPPED:
        return {
          strokeColor: "#8c8c8c", // Antd gray-8.
          buttonText: "Start Over",
          icon: "exception",
          headerText: "Stopped",
          description: "Stopped scan.",
          buttonAction: handleRestart,
        }
      case CURRENT_SCAN.COMPLETED:
        return scanStatus === SCAN_STATUS.UPLOADING
          ? {
              strokeColor: "#389e0d", // Antd green-7.
              buttonText: "Start Another Scan",
              icon: "active",
              headerText: "Scan Complete!",
              description: "Scan complete! Uploading...",
              buttonAction: handleRestart,
            }
          : {
              strokeColor: "#389e0d", // Antd green-7.
              buttonText: "Start Another Scan",
              icon: "success",
              headerText: "Scan Complete!",
              description: "Scan complete and uploaded.",
              buttonAction: handleRestart,
            }
      default:
        return {
          strokeColor: { "0%": "#A288FF", "99%": "#CB49EF" }, // Tracis purple.
          buttonText: "Stop",
          icon: "active",
          headerText: "Scanning!",
          description: "Scan in progress...",
          buttonAction: handleStop,
        }
    }
  }

  useEffect(() => {
    db.onScannerStatusChange(
      state.userOrgIds[0],
      state.scanner.id,
      onProgressChange
    )
  }, [])

  useEffect(() => {
    db.onScanStatusChange(
      state.userOrgIds[0],
      state.scanID,
      onScanProgressChange
    )
  }, [])

  return (
    <div>
      {showSkeleton ? (
        <Skeleton active={true} />
      ) : (
        <div>
          <Header>{progressFeatures.headerText}</Header>
          <div className={css.scanner}>{state.scanner.human_name}</div>
          <div className={css.lens}>
            <Lens lens={state.currentScanLens} />
          </div>
          <div style={{ display: "flex" }}>
            <Progress
              strokeColor={progressFeatures.strokeColor}
              percent={scannerStatus.percentComplete}
              status={progressFeatures.icon}
              showInfo={
                (scannerStatus.percentComplete > 0 &&
                  scannerStatus.percentComplete < 100) ||
                scannerStatus.currentScan === CURRENT_SCAN.STOPPED ||
                scanStatus === "COMPLETED"
              }
              style={
                (scannerStatus.percentComplete === 0 &&
                  scannerStatus.currentScan !== CURRENT_SCAN.STOPPED) ||
                (scannerStatus.percentComplete === 100 &&
                  scanStatus !== "COMPLETED")
                  ? { paddingRight: "18px" }
                  : {}
              }
            />
            {((scannerStatus.percentComplete === 0 &&
              scannerStatus.currentScan !== CURRENT_SCAN.STOPPED) ||
              (scannerStatus.percentComplete === 100 &&
                scanStatus !== "COMPLETED")) && (
              <div style={{ marginRight: "8px" }}>
                <LoadingOutlined style={{ fontSize: "x-small" }} />
              </div>
            )}
          </div>
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {progressFeatures.description}
          </Typography>
          <div className={css.button}>
            <Button
              type="primary"
              ghost
              size="large"
              onClick={progressFeatures.buttonAction}
              block
            >
              {progressFeatures.buttonText}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
