export const MACHINE = {
  BOOTING: "booting",
  READY: "ready to scan",
  SCANNING: "scanning",
  UNAVAILABLE: "unavailable",
  STARTING_SERVICE: "preparing for service",
  READY_TO_SERVICE: "ready to service",
  STOPPING_SERVICE: "ending service",
}

export const CURRENT_SCAN = {
  STARTED: "started",
  COMPLETED: "completed",
  STOPPED: "stopped",
  STOPPING: "stopping",
  ERROR: "error",
}

export const SCAN_STATUS = {
  STARTED: "STARTED",
  STOPPED: "STOPPED",
  UPLOADING: "UPLOADING",
  COMPLETED: "COMPLETED",
  READY_TO_REVIEW: "READY TO REVIEW",
  PARTIALLY_PROCESSED: "PARTIALLY PROCESSED",
  PROCESSED: "PROCESSED",
  CALIBRATED: "CALIBRATED",
  BAD: "BAD",
}

export const INSTRUCTION = {
  START: "start",
  STOP: "stop",
  EMPTY: "",
  START_DEMO: "demo",
  START_SERVICE: "service",
  STOP_SERVICE: "home",
}

export const STEPS = {
  SELECT_SCANNER: 0,
  SELECT_LENS: 1,
  ADD_LENS: 2,
  CALIBRATE_LENS: 4,
  FINAL_CHECKLIST: 5,
  SCANNING: 3,
  SELECT_SERIAL_NUMBER: 6,
}

export const DEMO_LENS = {
  make: "Celere",
  model: "HS",
  serial_number: "DEMOLENS",
  fstop: 1.8,
  focal_length: 50,
  length: 140,
  image_diameter: 32,
  curvature: 1.0,
  tstop: 2,
}

export const COLLECTION = {
  ORGANIZATIONS: "Organizations",
  SCANS: "Scans",
  LENSES: "Lenses",
  SCANNERS: "Scanners",
  LENS_TYPES: "LensTypes",
  USERS: "Users",
}
