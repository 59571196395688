import React, { useEffect } from "react"
import { Layout } from "antd"
import ScanWizard from "../../ScanWizard"
import LeftNav from "../LeftNav"
import SettingsPage from "../../Settings"
import ScanHistoryPage from "../../ScanHistory"
import InventoryPage from "../../Inventory"
import { Switch, Route, Redirect } from "react-router-dom"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import Loading from "../../common/Loading"

const AppLayout = ({ ...props }) => {
  const { Content } = Layout

  const [state, dispatch] = useGlobalState()
  useEffect(() => {
    const domain = props.user.email.split("@")[1]
    const betaUserFlag = ["tracis.eu"].includes(domain)
    dispatch({ type: "SET_BETA_USER", betaUserFlag: betaUserFlag })
  }, [props.user.email])

  return (
    <Layout className={state.collapsed ? css.collapsed : css.expanded}>
      <LeftNav />
      <Content className="fadeIn">
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/scan" />} />
          <Route path="/scan">
            <ScanWizard {...props} />
          </Route>
          <Route path="/inventory">
            <InventoryPage {...props} />
          </Route>
          <Route path="/settings">
            <SettingsPage {...props} />
          </Route>
          <Route path="/scan_history">
            <ScanHistoryPage {...props} />
          </Route>
        </Switch>
      </Content>
    </Layout>
  )
}

export default AppLayout
