import React, { useEffect, useState } from "react"
import { Button, Card, Dropdown, Menu, Modal, Badge, Row, Col } from "antd"
import { useGlobalState } from "../../../state"
import { DEMO_LENS } from "../../../state/constants"
import { generateScanID } from "../../../helpers/generateScanID"
import Header from "../Header"
import css from "./index.module.css"
import cx from "classnames"
import {
  MoreOutlined,
  DesktopOutlined,
  ToolOutlined,
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  UpCircleTwoTone,
} from "@ant-design/icons"
import { MACHINE, STEPS, SCAN_STATUS } from "../../../state/constants"
import { firestore as db } from "../../../state/firebase"

const { Meta } = Card

export default function StartScan({ setStep, user }) {
  const [state, dispatch] = useGlobalState()
  const [serviceModalVisible, setServiceModalVisible] = useState(false)
  const [serviceStatus, setServiceStatus] = useState("")
  const [activeScans, setActiveScans] = useState([])

  useEffect(() => {
    if (state.scanners.length === 1) {
      dispatch({ type: "SET_SCANNER", scanner: state.scanners[0] })
    }
  }, [state.scanners])

  useEffect(() => {
    if (state.scanner === null || state.scanner === undefined) return
    db.onServiceChange(
      state.userOrgIds[0],
      state.scanner.id,
      onServiceStatusChange
    )
  })

  useEffect(() => {
    if (serviceStatus === MACHINE.READY && serviceModalVisible) {
      setServiceModalVisible(false)
    }
  }, [serviceStatus])

  const mapServiceStatusToDialog = (status) => {
    switch (status) {
      case MACHINE.STARTING_SERVICE:
        return "Scanner moving to service position..."
      case MACHINE.READY_TO_SERVICE:
        return `Scanner is in service position.\n
        It is now safe to service ${
          state.scanner && state.scanner.human_name
        }.\n
        Press 'Done' when you're ready to use the machine for scanning again.`
      case MACHINE.STOPPING_SERVICE:
        return "Moving arms back to home positions..."
      default:
        return `It is now safe to scan on ${
          state.scanner && state.scanner.human_name
        }!`
    }
  }

  const onActiveScansChange = (docs) => {
    const scansByScanner = {}
    docs.forEach((scan) => {
      if (scansByScanner[scan.scanner_id]) {
        scansByScanner[scan.scanner_id].push(scan)
      } else {
        scansByScanner[scan.scanner_id] = [scan]
      }
    })
    setActiveScans(scansByScanner)
  }

  useEffect(() => {
    state.userOrgIds.length &&
      db.onActiveScansChange(state.userOrgIds[0], onActiveScansChange)
  }, [state.userOrgIds])

  const onServiceStatusChange = (doc) => {
    setServiceStatus(doc.status)
  }

  const showModal = () => setServiceModalVisible(true)

  const handleScannerSelect = (scanner) => {
    dispatch({ type: "SET_SCANNER", scanner: scanner })
  }

  const handleStartClick = () => {
    if (state.scanner && state.scanner.status === MACHINE.SCANNING) {
      dispatch({
        type: "SET_CURRENT_SCAN_LENS",
        lens: state.scanner.user_scan_params.lens,
      })
      dispatch({ type: "SET_SCAN_ID", scanID: state.scanner.scan_id })
      setStep(STEPS.SCANNING)
    } else {
      setStep(STEPS.SELECT_LENS)
    }
  }

  const handleStartDemoClick = () => {
    const scanID = generateScanID()
    dispatch({ type: "SET_SCAN_ID", scanID: scanID })
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: DEMO_LENS })
    dispatch({ type: "SET_DEMO_MODE", demoMode: true })
    db.getScanner(state.userOrgIds[0], state.scanner.id).then((doc) => {
      db.startDemo(state.userOrgIds[0], doc.id, user.email, scanID)
        .then((res) => console.log(res))
        .catch((err) => console.log(err))
    })
    setStep(STEPS.SCANNING)
  }

  const handleStartServiceClick = () => {
    dispatch({ type: "SET_SERVICE_MODE", serviceMode: true })
    db.getScanner(state.userOrgIds[0], state.scanner.id).then((doc) => {
      db.startService(state.userOrgIds[0], doc.id, user.email)
    })
    showModal()
  }

  const handleStopServiceClick = () => {
    dispatch({ type: "SET_SERVICE_MODE", serviceMode: false })
    db.stopService(state.userOrgIds[0], state.scanner.id)
  }

  const badgeInfo = (scannerId) => {
    return activeScans[scannerId] === undefined
      ? // No scans in progress.
        {
          title: "Scanner online.",
          count: (
            <CheckCircleTwoTone
              twoToneColor="#237804"
              style={{ fontSize: "22px" }}
            />
          ),
        }
      : activeScans[scannerId].filter((x) => x.status === SCAN_STATUS.STARTED)
          .length > 0
      ? // Scan in progress.
        {
          title: "Scan in progress.",
          count: (
            <ClockCircleTwoTone
              twoToneColor="red"
              style={{ fontSize: "22px" }}
            />
          ),
        }
      : // Scan uploading.
        {
          title: "Scan uploading.",
          count: (
            <UpCircleTwoTone
              twoToneColor="#a288ff"
              style={{ fontSize: "22px" }}
            />
          ),
        }
  }

  // Get and display initials of scanner ("Optimus Prime" -> "OP").
  const scannerTitle = (name) => {
    const initials = name
      .split(" ")
      .map((words) => words[0])
      .join("")
    return <h1 style={{ margin: "0", color: "#bfbfbf" }}>{initials}</h1>
  }

  const menu = (
    <Menu>
      <Menu.Item key="demo-menu-item" onClick={handleStartDemoClick}>
        <DesktopOutlined /> Start Demo
      </Menu.Item>
      <Menu.Item key="service-menu-item" onClick={handleStartServiceClick}>
        <ToolOutlined /> Service Scanner
      </Menu.Item>
    </Menu>
  )
  return (
    <div className={css.container}>
      <Modal
        title={`Servicing ${state.scanner && state.scanner.human_name}`}
        visible={serviceModalVisible}
        onOk={handleStopServiceClick}
        centered
        closable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            ghost
            disabled={false}
            onClick={handleStopServiceClick}
          >
            Done
          </Button>,
        ]}
      >
        {mapServiceStatusToDialog(serviceStatus)
          .split("\n")
          .map((line) => (
            <p key={line}>{line}</p>
          ))}
      </Modal>
      <div className={css.innerContainer}>
        <Header>Welcome!</Header>
        <Row className={css.layer2} type="flex" justify="space-around">
          {state.scanners.length > 0 &&
            state.scanners.map((scanner, index) => (
              <Col xs={state.scanners.length === 1 ? 24 : 12} key={scanner._id}>
                <Badge
                  className={css.badge}
                  style={{
                    position: "absolute",
                    top: "16px",
                    right: "16px",
                    backgroundColor: "none",
                  }}
                  count={badgeInfo(scanner._id).count}
                  title={badgeInfo(scanner._id).title}
                >
                  <Card
                    hoverable
                    bordered
                    onClick={() => handleScannerSelect(scanner)}
                    className={
                      state.scanner && state.scanner._id === scanner._id
                        ? cx(css.scannerCard, css.selected)
                        : css.scannerCard
                    }
                  >
                    <Meta
                      className={css.scannerTitle}
                      title={scannerTitle(scanner.human_name)}
                      description={scanner.human_name}
                    />
                  </Card>
                </Badge>
              </Col>
            ))}
        </Row>
        <div className={css.button}>
          {state.scanner && (
            <Dropdown.Button
              onClick={handleStartClick}
              overlay={menu}
              placement="bottomRight"
              icon={<MoreOutlined />}
            >
              {state.scanner && state.scanner.status === MACHINE.SCANNING
                ? "View Scan in Progress"
                : "Start Scan"}
            </Dropdown.Button>
          )}
          {state.scanners.length === 0 && (
            <Button type="primary" ghost disabled>
              No Scanners Found
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
