export const defaultState = {
  lensTypes: [],
  currentScanLens: null,
  userOrgIds: [],
  scanners: [],
  demoMode: false,
  collapsed: true,
  BETA_USER: false,
  loaded: true,
}
