export const reducer = (state, action) => {
  switch (action.type) {
    case "ALL_LENS_TYPES":
      return { ...state, lensTypes: action.lensTypes }
    case "SET_CURRENT_SCAN_LENS":
      return { ...state, currentScanLens: action.lens }
    case "SET_USER_ORGS_IDS":
      return { ...state, userOrgIds: action.userOrgIds }
    case "SET_SCANNERS_FOR_ORG":
      return { ...state, scanners: action.scanners }
    case "SET_SCANNER":
      return { ...state, scanner: action.scanner }
    case "RESET_WIZARD":
      return { ...state, currentScanLens: null, scanner: null }
    case "START_ANOTHER_SCAN":
      return { ...state, currentScanLens: null }
    case "SET_SCAN_ID":
      return { ...state, scanID: action.scanID }
    case "SET_DEMO_MODE":
      return { ...state, demoMode: action.demoMode }
    case "SET_SERVICE_MODE":
      return { ...state, serviceMode: action.serviceMode }
    case "SET_NAV_COLLAPSED":
      return { ...state, collapsed: action.collapsed }
    case "SET_BETA_USER":
      return { ...state, BETA_USER: action.betaUserFlag }
    case "SET_INVENTORY_LENS":
      return { ...state, inventoryLens: action.lens }
    case "SET_LOADED":
      return { ...state, loaded: action.loaded }
    default:
      throw new Error(
        `Unknown action: ${action.type}. Ensure this action has an associated reducer`
      )
  }
}
