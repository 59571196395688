import React, { useState, useEffect } from "react"
import { Card, Menu, Dropdown, Tooltip } from "antd"
import {
  MoreOutlined,
  DownloadOutlined,
  EyeOutlined,
  WarningOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons"
import css from "./index.module.css"
import cx from "classnames"
import { generateTimeString } from "../../../helpers/date"
import { SCAN_STATUS } from "../../../state/constants"
import { useGlobalState } from "../../../state"
import { useHistory } from "react-router-dom"

export default function ScanCard({ scan, mapStatusToVisualInfo, ...props }) {
  const [state, dispatch] = useGlobalState()
  const [loading, setLoading] = useState(true)
  const [lens, setLens] = useState({})
  const [inventoryURL, setInventoryURL] = useState("")
  const [downloadLinkDisabled, setDownloadLinkDisabled] = useState(true)
  const [viewLinkDisabled, setViewLinkDisabled] = useState(true)
  const history = useHistory()

  useEffect(() => {
    if (!scan) return
    else {
      setLoading(false)
      const lensObject = { lens_id: scan.lens_ref.id, ...scan.lens }
      setLens(lensObject)
      setInventoryURL(generateInventoryURL())
      setDownloadLinkDisabled(
        scan.downloadLink === undefined || scan.status !== SCAN_STATUS.PROCESSED
      )
      setViewLinkDisabled(
        scan.surfaces === undefined ||
          ![SCAN_STATUS.PROCESSED, SCAN_STATUS.PARTIALLY_PROCESSED].includes(
            scan.status
          )
      )
    }
  }, [scan])

  const handleInventoryClick = (e) => {
    e.preventDefault()
    dispatch({
      type: "SET_INVENTORY_LENS",
      lens: lens,
    })
    const url = new URL(e.target.href)
    history.push(url.pathname + url.search)
  }

  const menu = (
    <Menu>
      <Menu.Item key="downloadReport" disabled={downloadLinkDisabled}>
        {downloadLinkDisabled ? (
          <span>
            <WarningOutlined /> Download Unavailable
          </span>
        ) : (
          <a href={scan.downloadLink} target="_blank" rel="noopener noreferrer">
            <div>
              <DownloadOutlined /> Download Report
            </div>
          </a>
        )}
      </Menu.Item>
      <Menu.Item key="viewScan" disabled={viewLinkDisabled}>
        {viewLinkDisabled ? (
          <span>
            <WarningOutlined /> Viewing Unavailable
          </span>
        ) : (
          <a onClick={handleInventoryClick} href={inventoryURL}>
            <EyeOutlined /> View Report
          </a>
        )}
      </Menu.Item>
    </Menu>
  )

  const cardHead = {
    padding: "0px 16px",
    margin: 0,
    borderBottom: "none",
  }

  const cardBody = {
    display: "inline",
    padding: "0px 4px",
  }

  const generateInventoryURL = () => {
    if (scan.surfaces === undefined) return ""
    let url = new URL(window.location.origin)
    url.pathname = `/inventory/${scan.lens_ref.id}`
    let searchParams = new URLSearchParams(url)
    searchParams.append("date_a", scan.timestamp.seconds)
    url.search = searchParams.toString()
    return url.toString()
  }

  const scanVisualInfo = mapStatusToVisualInfo(scan.status)

  const tooltip = (
    <Tooltip placement="top" title={scanVisualInfo.explanation}>
      <QuestionCircleOutlined />
    </Tooltip>
  )

  return (
    <Card
      extra={
        <Dropdown
          className={css.threeDot}
          overlay={menu}
          trigger={["click"]}
          placement="bottomRight"
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <MoreOutlined />
          </a>
        </Dropdown>
      }
      bordered={false}
      className={css.card}
      bodyStyle={cardBody}
      headStyle={cardHead}
      title={
        <div className={css.titleStyle}>
          <h3>{scan.lens_make || lens.make}</h3>
          <h4>
            {scan.lens_model || lens.model} {lens.focal_length_mm}mm
          </h4>
          <h5>{lens.serialNumber}</h5>
        </div>
      }
    >
      <div className={css.cardImage}>
        <img className={css.externalImage} src="camera-lens.png" />
      </div>

      <div className={css.cardText}>
        <div className={css.scannedBy}>
          Scanned by
          <br />
          <span className={css.emphasis}>
            {scan.user.displayName ? scan.user.displayName : scan.user.email}
          </span>
        </div>

        <div className={css.scannerStatus}>
          Status
          <br />
          <span className={cx(css.emphasis, css.spaceAfter)}>
            {scanVisualInfo.friendlyStatus}
          </span>
          {tooltip}
        </div>
        <br />
        <div className={css.date}>
          {generateTimeString(scan.timestamp.seconds)}
        </div>
        <div className={css.statusIcon}>{scanVisualInfo.icon}</div>
      </div>
    </Card>
  )
}
