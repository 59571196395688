import React from "react"
import { Button } from "antd"
import { ToolOutlined } from "@ant-design/icons"

export default function Settings({ signOut, ...props }) {
  return (
    <div style={{ textAlign: "center", marginTop: "300px" }}>
      <h2>
        <ToolOutlined /> Settings
      </h2>
      <p style={{ fontSize: "1em" }}>Signed in as {props.user.email}</p>
      <Button type="primary" onClick={signOut}>
        Sign Out
      </Button>
    </div>
  )
}
