import React, { useState, useEffect } from "react"
import css from "./index.module.css"
import cx from "classnames"
import { notification, Breadcrumb, Divider } from "antd"
import StartScan from "./StartScan"
import SelectLens from "./SelectLens"
import AddLens from "./AddLens"
import Scanning from "./Scanning"
import UnknownLens from "./UnknownLens"
import FinalChecklist from "./FinalChecklist"
import SelectSerialNumber from "./SelectSerialNumber"
import { firestore as db } from "../../state/firebase"
import { MACHINE, STEPS } from "../../state/constants"
import { useGlobalState } from "../../state"
import { HomeFilled } from "@ant-design/icons"
import Loading from "../common/Loading"

export default function ScanWizard(props) {
  const [state, dispatch] = useGlobalState()
  const [step, setStep] = useState(0)

  const onStatusChange = (remoteScanner) => {
    if (
      remoteScanner.status === MACHINE.SCANNING &&
      remoteScanner.author_email !== undefined &&
      props.user.email !== remoteScanner.author_email
    ) {
      const { lens } = remoteScanner.user_scan_params
      notification["info"]({
        message: "Scan In Progress",
        description: `${remoteScanner.author_email} is currently scanning ${lens.make} ${lens.model} ${lens.focal_length}mm on ${remoteScanner.human_name}`,
        duration: 0,
        key: `${remoteScanner.id}-scan-in-progress`,
      })
    } else {
      notification.close(`${remoteScanner.id}-scan-in-progress`)
    }
  }

  useEffect(() => {
    db.onOrgsForUser(props.user.uid, (organizations) => {
      dispatch({ type: "SET_USER_ORGS_IDS", userOrgIds: organizations })
      organizations.forEach((org) => {
        db.onScannersForOrg(org, (scanners) => {
          dispatch({ type: "SET_SCANNERS_FOR_ORG", scanners: scanners })
        })
      })
    }).catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    const loaded =
      state.userOrgIds.length >= 1 &&
      state.scanners.length >= 1 &&
      props.user !== undefined
    loaded !== state.loaded && dispatch({ type: "SET_LOADED", loaded: loaded })
  }, [state.userOrgIds, state.scanners, props.user])

  useEffect(() => {
    if (state.scanner === undefined || state.scanner === null) return

    db.onScannerStatusChange(
      state.userOrgIds[0],
      state.scanner.id,
      onStatusChange
    )
  }, [state.scanner])

  const handleHomeClick = () => {
    dispatch({ type: "RESET_WIZARD" })
    setStep(STEPS.SELECT_SCANNER)
  }

  const getFocalLength = () => {
    // Avoid undefinedmm in breadcrumbs.
    if (state.currentScanLens.focal_length_mm)
      return state.currentScanLens.focal_length_mm
    else if (state.scanner.user_scan_params.lens.focal_length)
      return state.scanner.user_scan_params.lens.focal_length
    else if (state.currentScanLens.focal_length)
      return state.currentScanLens.focal_length
    else return state.scanner.user_scan_params.lens.focal_length_mm
  }

  const steps = [
    <StartScan setStep={setStep} {...props} />,
    <SelectLens setStep={setStep} {...props} />,
    <AddLens setStep={setStep} {...props} />,
    <Scanning setStep={setStep} {...props} />,
    <UnknownLens setStep={setStep} {...props} />,
    <FinalChecklist setStep={setStep} {...props} />,
    <SelectSerialNumber setStep={setStep} {...props} />,
  ]

  return (
    <div className={css.overviewContainer}>
      {state.loaded ? (
        <div className={cx("fadeIn", css.wizardContainer)}>
          <div className={css.wizard}>
            <Breadcrumb separator=">">
              {step > STEPS.SELECT_SCANNER && ( // Antd breadcrumb.items must be direct children of breadcrumbs hence the conditional repeat (neighboring JSX components must be wrapped in a div).
                <Breadcrumb.Item
                  onClick={handleHomeClick}
                  style={{ cursor: "pointer" }}
                >
                  <HomeFilled />
                </Breadcrumb.Item>
              )}
              {step > STEPS.SELECT_SCANNER && (
                <Breadcrumb.Item
                  onClick={() => setStep(STEPS.SELECT_LENS)}
                  style={{ cursor: "pointer" }}
                >
                  {state.scanner.human_name}
                </Breadcrumb.Item>
              )}
              {step === STEPS.ADD_LENS && (
                <Breadcrumb.Item style={{ cursor: "pointer" }}>
                  {state.currentScanLens ? "Lens Details" : "Adding Lens"}
                </Breadcrumb.Item>
              )}
              {step > STEPS.SELECT_LENS &&
                step !== STEPS.ADD_LENS &&
                state.currentScanLens && (
                  <Breadcrumb.Item style={{ cursor: "pointer" }}>
                    {`${state.currentScanLens.model} ${getFocalLength()}mm`}
                  </Breadcrumb.Item>
                )}
            </Breadcrumb>
            {step > STEPS.SELECT_SCANNER && (
              <Divider
                style={{
                  background: "#34383b",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              ></Divider>
            )}
            <div className={css.wizardBody}>{steps[step]}</div>
          </div>
        </div>
      ) : (
        <Loading empty />
      )}
    </div>
  )
}
