import React, { useState, useEffect } from "react"
import { Button, Checkbox, Typography } from "antd"
import Header from "../Header"
import Lens from "../../common/Lens"
import { generateScanID } from "../../../helpers/generateScanID"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import { firestore as db } from "../../../state/firebase"
import { MACHINE, STEPS } from "../../../state/constants"

const startVerbs = [
  "Commence!",
  "Initiate!",
  "Engage!",
  "Beam me up!",
  "Do the thing!",
  "Dance!",
]

const verb = startVerbs[Math.floor(Math.random() * startVerbs.length)]

export default function FinalChecklist({ setStep, user }) {
  const [checkList, setCheckList] = useState({
    iris: false,
    focus: false,
    door: false,
  })
  const [checklistEnabled, setChecklistEnabled] = useState(true)
  const [state, dispatch] = useGlobalState()

  const onStatusChecklistChange = (doc) => {
    // We enable our checklist, but only if the machine is ready to start another scan.
    setChecklistEnabled(doc.status === MACHINE.READY)
  }

  useEffect(() => {
    db.onScannerStatusChange(
      state.userOrgIds[0],
      state.scanner.id,
      onStatusChecklistChange
    )
  }, [state.scanner])

  const canContinue = Object.values(checkList).reduce((acc, val) => acc && val)

  const reformatLensKeys = (oldLens) => {
    const defaults = {
      fstop: 1.8,
      image_diameter: 32,
      mount: "PL",
    }

    // Remove object fields that are null
    Object.keys(oldLens).forEach(
      (key) => oldLens[key] === null && delete oldLens[key]
    )

    const lens = Object.assign({}, defaults, oldLens)
    lens.focal_length = oldLens.focal_length_mm
    delete lens["calibrated_front_rear"]
    delete lens["focal_length_mm"]
    if (lens.newLens) delete lens["newLens"]

    return lens
  }

  const handleClick = () => {
    const { currentScanLens } = state
    const {
      make,
      model,
      focal_length_mm,
      focal_length,
      newLens,
    } = currentScanLens
    let lens

    if (newLens) {
      lens = currentScanLens
    } else {
      lens = state.lensTypes.find(
        (lensType) =>
          lensType.make === make &&
          lensType.model === model &&
          (lensType.focal_length_mm === focal_length ||
            lensType.focal_length_mm === focal_length_mm)
      )
    }
    lens.lens_id = currentScanLens.id
    lens.serial_number = currentScanLens.serial_number
    if (lens === undefined)
      throw new Error(
        `No lenses found of type: '${make}' '${model}' '${focal_length_mm}'`
      )
    const scanID = generateScanID()
    dispatch({ type: "SET_SCAN_ID", scanID: scanID })
    db.startScanning(
      state.userOrgIds[0],
      state.scanner.id,
      reformatLensKeys(lens),
      user,
      scanID
    )
      .then((res) => console.log(res))
      .catch((err) => console.log(err))

    setStep(STEPS.SCANNING)
  }
  return (
    <div>
      <Header>Get ready to start a scan!</Header>
      <div className={css.scanner}>{state.scanner.human_name}</div>
      <div className={css.lens}>
        <Lens lens={state.currentScanLens} />
      </div>
      {checklistEnabled ? (
        <div>
          <div className={css.checklist}>
            <div className={css.form}>
              <Checkbox
                onChange={(e) =>
                  setCheckList({ ...checkList, iris: e.target.checked })
                }
              >
                Iris Wide Open
              </Checkbox>
              <br />
              <br />
              <Checkbox
                onChange={(e) =>
                  setCheckList({ ...checkList, focus: e.target.checked })
                }
              >
                Focus At Infinity
              </Checkbox>
              <br />
              <br />
              <Checkbox
                onChange={(e) =>
                  setCheckList({ ...checkList, door: e.target.checked })
                }
              >
                Close Door
              </Checkbox>
            </div>
          </div>
          <div className={css.button}>
            <Button
              size="large"
              type="primary"
              ghost
              onClick={handleClick}
              disabled={!canContinue}
              block
            >
              {canContinue ? verb : "Confirm Checklist to Continue"}
            </Button>
          </div>
        </div>
      ) : (
        <Typography>{`${state.scanner.human_name} is currently in use. You can start your scan from here as soon as the scan in progress finishes.`}</Typography>
      )}
    </div>
  )
}
